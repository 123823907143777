import Typography from '@gain/components/typography'
import { Asset } from '@gain/rpc/app-model'
import { styled, useTheme } from '@mui/material/styles'
import { SxProps } from '@mui/system'
import { useMeasure } from 'react-use'

const StyledContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'disableAutoMaxLines',
})<{ disableAutoMaxLines: boolean }>(({ theme, disableAutoMaxLines }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.75),
  ...(!disableAutoMaxLines && {
    minHeight: 0,
  }),
}))

const StyledDescriptionContainer = styled('div')({
  flex: 1,
  minHeight: 0,
  overflow: 'hidden',
})

const StyledDescription = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'maxLines',
})<{ maxLines: number }>(({ maxLines }) => ({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: maxLines,
  overflow: 'hidden',
}))

interface AssetBusinessDescriptionProps {
  asset: Asset
  disableAutoMaxLines?: boolean
  maxLines?: number
  sx?: SxProps
}

export default function AssetBusinessDescription({
  asset,
  disableAutoMaxLines = false,
  maxLines = 10,
  ...props
}: AssetBusinessDescriptionProps) {
  const [ref, { height }] = useMeasure<HTMLDivElement>()
  const theme = useTheme()

  let nrOfLines = maxLines
  if (!disableAutoMaxLines) {
    nrOfLines = Math.floor(height / parseInt(`${theme.typography.body2.lineHeight}`, 10))
  }

  return (
    <StyledContainer
      disableAutoMaxLines={!asset.description?.atAGlance}
      {...props}>
      {asset.description?.atAGlance && (
        <StyledDescriptionContainer ref={ref}>
          <StyledDescription
            color={'text.primary'}
            marginTop={0}
            maxLines={nrOfLines || maxLines}
            variant={'body2'}>
            {asset.description?.atAGlance}
          </StyledDescription>
        </StyledDescriptionContainer>
      )}
    </StyledContainer>
  )
}
