import { CompanyIcon } from '@gain/components/icons'
import { data, SearchResultItem } from '@gain/rpc/app-model'
import LoadingButton from '@mui/lab/LoadingButton'
import Autocomplete, {
  autocompleteClasses,
  AutocompleteInputChangeReason,
} from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import Dialog, { dialogClasses, DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import { styled } from '@mui/material/styles'
import { AutocompleteChangeReason } from '@mui/material/useAutocomplete'
import { useCallback, useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { rpcClient } from '../../api/rpc-client'
import { useOpenDialog } from '../../common/dialog'
import DialogSearch from '../../common/dialog/dialog-search'
import DialogHeader from '../../common/dialog-header'
import { ObjectListItem, objectListItemClasses } from '../object-list-item'
import AddAssetsDialogList from './add-assets-dialog-list'

const StyledDialog = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    maxWidth: 518,
  },
})

const StyledSearchContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'bottomBorder',
})<{ bottomBorder: boolean }>(({ theme, bottomBorder }) => ({
  padding: theme.spacing(2, 1.5),
  border: `0 solid ${theme.palette.divider}`,
  borderBottomWidth: bottomBorder ? 1 : 0,
}))

const StyledPaperComponent = styled('div')(({ theme }) => ({
  [`&.${autocompleteClasses.paper}`]: {
    backgroundColor: theme.palette.background.paper,

    [`& .${autocompleteClasses.option}`]: {
      padding: 0,
      '&.Mui-focused': {
        backgroundColor: theme.palette.grey['100'],
      },
    },
  },
}))

const StyledListItem = styled('li')({
  [`&.${autocompleteClasses.option}[aria-disabled="true"]`]: {
    opacity: 1,
    [`& .${objectListItemClasses.text}, & .${objectListItemClasses.image}`]: {
      opacity: 0.4,
    },
  },
})

interface AddAssetsDialogProps {
  open: boolean
  onClose: () => void
  onSave: (assetIds: number[]) => Promise<void>
  title?: string
  buttonText?: string
  alreadyAddedAssetIds?: number[]
}

export default function AddAssetsDialog({
  alreadyAddedAssetIds,
  open,
  onClose,
  onSave,
  title = 'Start by adding at least 1 company',
  buttonText = 'Save',
}: AddAssetsDialogProps & DialogProps) {
  const openDialog = useOpenDialog()
  const [text, setText] = useState('')
  const [autocompleteOpen, setAutocompleteOpen] = useState(false)
  const [searchSuggestions, setSearchSuggestions] = useState<SearchResultItem[]>([])
  const [selectedAssets, setSelectedAssets] = useState<SearchResultItem[]>([])
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [isSaving, setIsSaving] = useState(false)

  const resetState = () => {
    setAutocompleteOpen(false)
    setSelectedAssets([])
    setText('')
    setSearch('')
    setSearchSuggestions([])
    setLoading(false)
  }

  useEffect(() => {
    if (open) {
      resetState()
    }
  }, [open])

  const handleConfirmClose = useCallback(() => {
    if (selectedAssets.length > 0) {
      openDialog({
        title: 'Discard changes',
        message:
          'The selected companies haven’t been added yet. Are you sure you want to discard your changes?',
        confirmText: 'Discard changes',
        cancelText: 'Cancel',
        onConfirm: onClose,
      })
    } else {
      onClose()
    }
  }, [onClose, openDialog, selectedAssets.length])

  const debouncedSetSearch = useDebouncedCallback((newSearch: string) => {
    setSearch(newSearch)
  }, 250)

  const handleInputChange = useCallback(
    (_, newValue: string, reason: AutocompleteInputChangeReason) => {
      if (reason === 'reset') {
        return
      }

      setText(newValue)
      debouncedSetSearch(newValue)

      if (newValue !== '') {
        setLoading(true) // Show loading indicator immediately even though search is debounced
      } else {
        setAutocompleteOpen(false)
        setSearchSuggestions([])
      }
    },
    [debouncedSetSearch]
  )

  const handleChange = useCallback(
    (_, newValue: SearchResultItem[], reason: AutocompleteChangeReason) => {
      if (reason === 'selectOption') {
        resetState()
        setSelectedAssets(newValue)
      }
    },
    [setSelectedAssets]
  )

  const handleSave = useCallback(async () => {
    try {
      setIsSaving(true)
      const assetIds = selectedAssets.map((asset) => asset.id)
      await onSave(assetIds)
    } finally {
      setIsSaving(false)
      onClose()
    }
  }, [onSave, selectedAssets, onClose])

  const handleRemove = useCallback(
    (asset: SearchResultItem) => {
      setSelectedAssets(selectedAssets.filter((item) => item.id !== asset.id))
    },
    [setSelectedAssets, selectedAssets]
  )

  // Executes a search after the debounced search changes
  useEffect(() => {
    if (search === '') {
      setSearchSuggestions([])
      return
    }

    const searchText = async () => {
      setSearchSuggestions([])
      setAutocompleteOpen(false)

      try {
        const results = await rpcClient.rpc<SearchResultItem[]>(
          data.search({
            query: search,
            limit: 20,
            assets: true,
            entities: false,
            industries: false,
            investors: false,
            advisors: false,
            conferenceEditions: false,
          })
        )
        setSearchSuggestions(results)
      } finally {
        setLoading(false)
        setAutocompleteOpen(true)
      }
    }

    searchText()
  }, [search, setSearchSuggestions, setLoading, setAutocompleteOpen])

  return (
    <StyledDialog
      onClose={handleConfirmClose}
      open={open}
      fullWidth>
      <DialogHeader
        onCloseClick={handleConfirmClose}
        title={title}
      />
      <StyledSearchContainer bottomBorder={selectedAssets.length > 0}>
        <Autocomplete
          filterOptions={(availableOptions) =>
            availableOptions.filter(
              (option) => !selectedAssets.find((item) => item.id === option.id)
            )
          }
          forcePopupIcon={false}
          getOptionDisabled={(option) => alreadyAddedAssetIds?.includes(option.id) ?? false}
          getOptionLabel={(option) => option.name || ''}
          inputValue={text}
          isOptionEqualToValue={(option, currentValue) => option.id === currentValue.id}
          loading={loading}
          noOptionsText={'No asset found'}
          onChange={handleChange}
          onClose={() => {
            setAutocompleteOpen(false)
          }}
          onInputChange={handleInputChange}
          onOpen={() => {
            setAutocompleteOpen(searchSuggestions.length > 0)
          }}
          open={autocompleteOpen}
          options={searchSuggestions}
          PaperComponent={StyledPaperComponent}
          renderInput={(params) => (
            <DialogSearch
              loading={loading}
              placeholder={'Enter company name'}
              autoFocus
              {...params}
            />
          )}
          renderOption={(props, item) => {
            return (
              <StyledListItem
                {...props}
                key={item.id}>
                <ObjectListItem
                  key={item.id}
                  description={item.description}
                  endAdornment={
                    alreadyAddedAssetIds?.includes(item.id) && (
                      <Chip
                        color={'success'}
                        label={'Already in list'}
                        size={'small'}
                      />
                    )
                  }
                  icon={<CompanyIcon />}
                  imageUrl={item.imageUrl}
                  name={item.name}
                  region={item.regions[0]}
                  type={'asset'}
                />
              </StyledListItem>
            )
          }}
          renderTags={() => null}
          value={selectedAssets || null}
          autoSelect
          disableClearable
          fullWidth
          multiple
          openOnFocus
        />
      </StyledSearchContainer>

      <AddAssetsDialogList
        items={selectedAssets}
        onRemove={handleRemove}
      />

      <DialogActions>
        <LoadingButton
          color={'primary'}
          disabled={selectedAssets.length === 0}
          loading={isSaving}
          onClick={handleSave}
          variant={'contained'}
          fullWidth>
          {buttonText}
        </LoadingButton>
      </DialogActions>
    </StyledDialog>
  )
}
