import * as Jsonrpc from '@gain/jsonrpc'
import * as Shared from '@gain/rpc/shared-model'

export enum AssetSourceType {
  Industry = 'industry',
  Investor = 'investor',
  IndustrySegment = 'industrySegment',
  CustomAssetList = 'customAssetList',
  CustomAssetQueryList = 'customAssetQueryList',
  ConferenceEdition = 'conferenceEdition',
}

export enum AssetSummaryValuationPeriod {
  LastFiscalYear = 'lastFiscalYear',
  CurrentFiscalYear = 'currentFiscalYear',
  NextFiscalYear = 'nextFiscalYear',
  LastTwelveMonths = 'lastTwelveMonths',
  NextTwelveMonths = 'nextTwelveMonths',
}

export enum AuthenticationType {
  Email = 'email',
  Sso = 'sso',
}

export enum BrokerRecommendationPeriodicity {
  Weekly = 'weekly',
  BiWeekly = 'biWeekly',
  Monthly = 'monthly',
  BiMonthly = 'biMonthly',
  Quarterly = 'quarterly',
}

export enum CustomAssetListEnum {
  CustomAssetList = 'CustomAssetList',
  CustomAssetQuery = 'CustomAssetQuery',
  ConferenceEdition = 'ConferenceEdition',
}

export enum DealCloudStatus {
  Created = 'created',
  Updated = 'updated',
  Existing = 'existing',
  Failed = 'failed',
}

export enum ListRelatedAssetsSource {
  Industry = 'industry',
  IndustryMarketSegment = 'industryMarketSegment',
  CustomAssetList = 'customAssetList',
}

export enum ObjectType {
  Asset = 'asset',
  Industry = 'industry',
  Investor = 'investor',
  Entity = 'entity',
  Advisor = 'advisor',
  Deal = 'deal',
  Person = 'person',
}

export enum SearchItemType {
  CuratedAsset = 'curatedAsset',
  AutomatedAsset = 'automatedAsset',
  Industry = 'industry',
  Investor = 'investor',
  Entity = 'entity',
  Advisor = 'advisor',
  ConferenceEdition = 'conferenceEdition',
}

export enum TicketAttributeType {
  CompanyName = 'companyName',
  CompanyWebsite = 'companyWebsite',
  GainProCompanyUrl = 'gainProCompanyUrl',
  CompanyRegion = 'companyRegion',
  InvestorName = 'investorName',
  GainProInvestorUrl = 'gainProInvestorUrl',
}

export enum TicketType {
  RequestCompanyProfile = 'requestCompanyProfile',
  ShareCompanyFeedback = 'shareCompanyFeedback',
  ShareInvestorFeedback = 'shareInvestorFeedback',
}

export interface ActiveConsolidator {
  assetId: number
  assetName: string
  assetDescription: string | null
  assetLogoFileUrl: string | null
  assetRevenueEur: number | null
  assetRevenueYear: number | null
  assetEbitdaEur: number | null
  assetEbitdaYear: number | null
  assetFte: number | null
  assetRegion: string | null
  dealCount: number
  lastDealYear: number | null
  lastDealMonth: number | null
}

export interface ActiveInvestor {
  investorId: number
  investorName: string
  investorOperationalHqCountryCode: string | null
  investorLogoFileUrl: string | null
  assetCount: number
  dealCount: number
  medianEbitdaEur: number | null
  assetEbitdasEur: number[]
  lastDealYear: number | null
  lastDealMonth: number | null
}

export interface ActiveStrategicAcquirer {
  assetId: number | null
  assetName: string
  assetDescription: string | null
  assetLogoFileUrl: string | null
  assetRevenueEur: number | null
  assetRevenueYear: number | null
  assetEbitdaEur: number | null
  assetEbitdaYear: number | null
  assetFte: number | null
  assetRegion: string | null
  dealCount: number
  lastDealYear: number | null
  lastDealMonth: number | null
}

export interface Address {
  readonly id: number
  city: string
  region: string | null
  countryCode: string
  formattedAddress: string
  lng: number
  lat: number
  locationType: string | null
}

export interface Advisor {
  readonly id: number
  readonly live: boolean
  readonly createdAt: string
  readonly updatedAt: string
  readonly publishedAt: string | null
  readonly unpublishedAt: string | null
  name: string | null
  logoFileId: number | null
  readonly logoFileUrl: string | null
  linkedInUrl: string | null
  linkedinExternalId: string | null
  webUrl: string | null
  founders: string | null
  operationalHqCity: string | null
  operationalHqCountryCode: string | null
  yearFounded: number | null
  advisoryActivities: Shared.AdvisoryActivity[]
  aliases: AdvisorAlias[]
  fteMeasurements: AdvisorFteMeasurement[]
}

export interface AdvisorAlias {
  readonly id: number
  readonly advisorId: number
  alias: string
  order: number
}

export interface AdvisorAssetClient {
  assetId: number
  assetName: string
  assetLogoFileUrl: string | null
  assetDescription: string | null
  assetFte: number | null
  assetFteYear: number | null
  assetFteRange: string | null
  dealCount: number
  dealIds: number[]
  lastDeal: Deal
}

export interface AdvisorDealListItem {
  advisorId: number | null
  advised: Shared.DealAdvisorAdvised
  advisedOn: Shared.AdvisoryActivity[]
  readonly webUrl: string
  readonly id: number
  publicationDate: string | null
  asset: string | null
  assetLogoFileUrl: string | null
  linkedAssetId: number | null
  linkedAssetDescription: string | null
  region: string | null
  sector: string | null
  subsector: string | null
  currency: string | null
  division: string | null
  dealStatus: Shared.DealStatus | null
  dealType: Shared.DealType | null
  reasons: Shared.DealReason[]
  announcementDate: string
  announcementDateYear: number | null
  announcementDateMonth: number | null
  tags: string[]
  tagIds: number[]
  investorIds: number[]
  advisorIds: number[]
  readonly live: boolean
  highlightedBuyerId: number | null
  highlightedBuyerType: Shared.DealSideType | null
  highlightedBuyerReason: Shared.DealReason | null
  highlightedBuyerName: string | null
  highlightedBuyerDivision: string | null
  highlightedBuyerRegion: string | null
  highlightedBuyerShare: Shared.DealSideShare | null
  highlightedBuyerSharePct: number | null
  highlightedSellerId: number | null
  highlightedSellerType: Shared.DealSideType | null
  highlightedSellerReason: Shared.DealReason | null
  highlightedSellerName: string | null
  highlightedSellerDivision: string | null
  highlightedSellerRegion: string | null
  highlightedSellerShare: Shared.DealSideShare | null
  highlightedSellerSharePct: number | null
  readonly buyersInfo: DealSummarySide[]
  readonly sellersInfo: DealSummarySide[]
  buyerAdvisorIds: number[]
  sellerAdvisorIds: number[]
  buyerAssetIds: number[]
  buyerInvestorIds: number[]
  buyerReasons: Shared.DealReason[]
  buyerShares: Shared.DealSideShare[]
  buyerSharePcts: number[]
  buyerLogoFileUrls: string[]
  sellerAssetIds: number[]
  sellerInvestorIds: number[]
  sellerReasons: Shared.DealReason[]
  sellerShares: Shared.DealSideShare[]
  sellerSharePcts: number[]
  sellerLogoFileUrls: string[]
  buyers: number
  buyerAssetNames: string[]
  buyerInvestorNames: string[]
  buyerNames: string[]
  buyerTypes: Shared.DealSideType[]
  buyerLeadingParties: boolean[]
  buyerLinkedIds: number[]
  sellers: number
  sellerAssetNames: string[]
  sellerInvestorNames: string[]
  sellerNames: string[]
  sellerTypes: Shared.DealSideType[]
  sellerLeadingParties: boolean[]
  sellerLinkedIds: number[]
  fte: number | null
  fteYear: number | null
  revenue: number | null
  revenueEur: number | null
  revenueYear: number | null
  ebitda: number | null
  ebitdaEur: number | null
  ebitdaYear: number | null
  ebit: number | null
  ebitEur: number | null
  ebitYear: number | null
  totalAssets: number | null
  totalAssetsEur: number | null
  totalAssetsYear: number | null
  ev: number | null
  evEur: number | null
  evYear: number | null
  equity: number | null
  equityEur: number | null
  equityYear: number | null
  evEbitdaMultiple: number | null
  evEbitdaMultipleYear: number | null
  evEbitMultiple: number | null
  evEbitMultipleYear: number | null
  evRevenueMultiple: number | null
  evRevenueMultipleYear: number | null
  evTotalAssetsMultiple: number | null
  evTotalAssetsMultipleYear: number | null
  fundingRoundAmountRaised: number | null
  fundingRoundAmountRaisedEur: number | null
  fundingRoundAmountRaisedYear: number | null
  fundingRoundPreMoneyValuation: number | null
  fundingRoundPreMoneyValuationEur: number | null
  fundingRoundPreMoneyValuationYear: number | null
  fundingRoundPostMoneyValuation: number | null
  fundingRoundPostMoneyValuationEur: number | null
  fundingRoundPostMoneyValuationYear: number | null
  fundingRoundType: Shared.DealFundingRoundType | null
  readonly gainProUrl: string
}

export interface AdvisorDealsList {
  items: AdvisorDealListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface AdvisorFteMeasurement {
  readonly id: number
  readonly advisorId: number
  employeeCount: number
  determinedAt: string
}

export interface AdvisorInvestorClient {
  investorId: number
  investorName: string
  investorLogoFileUrl: string | null
  assets: DealAdvisorAsset[]
  assetsCount: number
  dealCount: number
  dealIds: number[]
  dealEbitdasEur: number[]
  lastDealDate: string | null
}

export interface AdvisorList {
  items: AdvisorListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface AdvisorListItem {
  readonly id: number
  readonly live: boolean
  readonly publishedAt: string
  name: string
  logoFileId: number | null
  readonly logoFileUrl: string | null
  linkedInUrl: string | null
  url: string | null
  webUrl: string | null
  operationalHqCity: string | null
  operationalHqCountryCode: string | null
  founders: string | null
  yearFounded: number | null
  aliases: string[]
  advisoryActivities: Shared.AdvisoryActivity[]
  dealIds: number[]
  coreFocus: Shared.AdvisoryActivity | null
  dealAdvisedOn: Shared.AdvisoryActivity[]
  dealRegions: string[]
  dealSectors: string[]
  dealSubsectors: string[]
  dealCount: number
  dealCountAdvisedSellers: number
  dealCountAdvisedBuyers: number
  dealCountAdvisedUnknown: number
  dealEbitdasEur: number[]
  dealEbitdaMedianEur: number | null
  fte: number | null
  fteRange: string | null
  fteRangeCategory: number | null
  readonly gainProUrl: string
  readonly deals: AdvisorSummaryAdvisedDeal[]
  dealCountFiltered: number
}

export interface AdvisorSummaryAdvisedDeal {
  dealId: number
  advisoryActivities: Shared.AdvisoryActivity[]
  party: Shared.DealAdvisorAdvised
}

export interface AnnualReport {
  readonly id: number
  readonly legalEntityId: number
  companyName: string | null
  bookYearStart: string | null
  bookYearEnd: string
  publicationDate: string | null
  type: string
  amendment: boolean
  description: string
  comment: string
  revisionDate: string | null
  createdAt: string | null
  isReadyToParse: boolean
  parsedAt: string | null
  parserVersion: number | null
  parseError: string | null
  files: AnnualReportFile[]
  financialResults: AnnualReportFinancialResult[]
  readonly items: AnnualReportItem[]
}

export interface AnnualReportDownloadURLs {
  fileURL: string
}

export interface AnnualReportFile {
  readonly id: number
  fileId: number
  documentId: string | null
  type: string | null
  automatedDownload: boolean
  format: string
  language: string | null
  internal: boolean
  createdAt: string
  processedAt: string | null
  processedBy: string | null
  processorVersion: string | null
  processingError: string | null
  readonly filename: string
}

export interface AnnualReportFinancialResult {
  readonly id: number
  readonly annualReportId: number
  year: number
  revenue: number | null
  grossMargin: number | null
  ebitda: number | null
  ebit: number | null
  totalAssets: number | null
  capex: number | null
  debt: number | null
  cash: number | null
  netDebt: number | null
  inventory: number | null
  receivables: number | null
  payables: number | null
  capital: number | null
  fte: number | null
}

export interface AnnualReportItem {
  readonly id: number
  type: string
  title: string
  source: string
  fragmentId: string
  issue: string | null
  readonly fields: AnnualReportItemField[]
}

export interface AnnualReportItemField {
  readonly id: number
  type: string
  title: string
  code: string
  fragmentId: string
  value: number | null
  bool: boolean | null
  text: string | null
  date: Date | null
  issue: string | null
}

export interface Article {
  readonly id: number
  readonly published: boolean
  type: Shared.ArticleType
  date: string
  title: string | null
  body: string | null
  largeImageFileId: number | null
  readonly largeImageFileUrl: string | null
  readonly largeImageFilename: string | null
  smallImageFileId: number | null
  readonly smallImageFileUrl: string | null
  readonly smallImageFilename: string | null
  readonly highlightedAssetId: number | null
  readonly highlightedInvestorId: number | null
  readonly highlightedIndustryId: number | null
  regions: string[]
  sector: string | null
  subsector: string | null
  category: Shared.ArticleCategory | null
  linkedAssets: ArticleAsset[]
  linkedIndustries: ArticleIndustry[]
  linkedInvestors: ArticleInvestor[]
  linkedDealId: number | null
}

export interface ArticleAsset {
  readonly id: number
  readonly articleId: number
  assetId: number
  order: number
}

export interface ArticleIndustry {
  readonly id: number
  readonly articleId: number
  industryId: number
  order: number
}

export interface ArticleInvestor {
  readonly id: number
  readonly articleId: number
  investorId: number
  order: number
}

export interface ArticleList {
  items: ArticleListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface ArticleListItem {
  readonly id: number
  type: string
  date: string
  year: number
  week: number
  title: string | null
  body: string | null
  regions: string[]
  sector: string | null
  subsector: string | null
  category: string | null
  highlightedAssetId: number | null
  highlightedAssetName: string | null
  highlightedAssetRegion: string | null
  highlightedAssetLogoFileUrl: string | null
  highlightedInvestorId: number | null
  highlightedIndustryId: number | null
  smallImageFileId: number | null
  largeImageFileId: number | null
  smallImageFileUrl: string | null
  largeImageFileUrl: string | null
  linkedAssetIds: number[]
  linkedAssetNames: string[]
  linkedAssetRegions: string[]
  linkedAssetLogoFileUrls: string[]
  linkedDealId: number | null
  linkedInvestorBuyerIds: number[]
  linkedInvestorBuyerNames: string[]
  linkedInvestorSellerIds: number[]
  linkedInvestorSellerNames: string[]
}

export interface Asset {
  readonly id: number
  readonly live: boolean
  readonly profileLive: boolean
  profileType: Shared.AssetProfileType
  readonly profileInConversion: boolean
  log: string
  linkedinCompanyId: number | null
  readonly subsidiaryPath: number[] | null
  readonly createdAt: string
  readonly updatedAt: string | null
  financialsAt: string | null
  readonly publishedAt: string | null
  readonly unpublishedAt: string | null
  generalInfo: AssetGeneralInfo | null
  description: AssetDescription | null
  segmentation: AssetSegmentation | null
  market: AssetMarket | null
  rating: AssetRating | null
  readonly financialLatests: AssetLatestFinancials | null
  readonly nextDeal: AssetNextDealPrediction | null
  urls: Url[]
  tags: AssetTag[]
  financialResults: AssetFinancialResult[]
  financialFootnotes: AssetFinancialFootnote[]
  readonly financialPredictions: AssetFinancialPrediction[]
  shareholders: AssetShareholder[]
  pros: AssetPro[]
  cons: AssetCon[]
  managers: AssetManager[]
  competitors: AssetCompetitor[]
  sources: AssetSource[]
  readonly excelFiles: AssetExcelFile[]
  legalEntities: AssetLegalEntity[]
  listedSecurities: AssetListedSecurity[]
  annualReports: AssetAnnualReport[]
  aliases: AssetAlias[]
  fteMeasurements: AssetFteMeasurement[]
}

export interface AssetAdvisor {
  advisorId: number
  advisorName: string
  advisorLogoFileUrl: string | null
  dealCount: number
  dealIds: number[]
  lastDeal: Deal
  lastDealAdvised: Shared.DealAdvisorAdvised
  lastDealAdvisedOn: Shared.AdvisoryActivity[]
}

export interface AssetAlias {
  readonly id: number
  readonly assetId: number
  alias: string
  order: number
}

export interface AssetAnnualReport {
  annualReportFileId: number
  order: number
  financials: boolean
}

export interface AssetChart {
  readonly id: number
  readonly assetSegmentationId: number
  of: string
  by: string
  periodFrom: number | null
  periodTo: number | null
  order: number
  items: AssetChartItem[]
}

export interface AssetChartItem {
  readonly id: number
  readonly assetChartId: number
  title: string
  shareFrom: number | null
  shareTo: number | null
  sharePct: number | null
  cagr: number | null
  order: number
}

export interface AssetCompetitor {
  readonly assetId: number
  competitorAssetId: number
  order: number
  competitorId: number
}

export interface AssetCon {
  readonly id: number
  readonly assetId: number
  text: string
  order: number
}

export interface AssetDescription {
  readonly assetId: number
  short: string
  atAGlance: string
  esg: string
  furtherInformation: string
  history: string
  acquisitions: string
  strategy: string
}

export interface AssetExcelFile {
  readonly id: number
  readonly assetId: number
  linkedAt: string
  importedFinancials: boolean
  importedCharts: boolean
  comment: string
}

export interface AssetFinancialFootnote {
  readonly id: number
  readonly assetId: number
  text: string
  order: number
}

export interface AssetFinancialPrediction {
  readonly id: number
  readonly assetId: number
  year: number
  ebitda: number
  multiple: number
  enterpriseValue: number
  debtMultiple: number
  debtQuantum: number
  equityTicket: number
  details: AssetFinancialPredictionDetails | null
}

export interface AssetFinancialPredictionDetails {
  readonly assetFinancialPredictionId: number
  ebitdaBase: number
  ebitdaYear: number
  ebitdaGrowthPct: number
  multipleMarketBase: number
  multipleSectorCorrection: number
  multipleSizeCorrection: number
  multipleGrowthCorrection: number
  multipleThinBusinessModelCorrection: number
  multipleProfitabilityCorrection: number
  multipleCashConversionCorrection: number
  multipleResilienceCorrection: number
  multipleProfileCorrection: number
  multipleOutlierCapCorrection: number
  multipleResultNotRounded: number
  multipleMin: number
  multipleMax: number
  evEbitda: number
  evMultiple: number
  debtMultipleCeiling: number
  debtMultipleFloor: number
  debtMultipleCushion: number
  debtMultipleResilienceMax: number | null
  debtQuantumEbitda: number
  debtQuantumMultiple: number
  equityTicketEv: number
  equityTicketDebt: number
}

export interface AssetFinancialResult {
  readonly id: number
  readonly assetId: number
  year: number
  periodicity: Shared.FinancialResultPeriodicityType
  isForecast: boolean
  revenue: FinancialResultAmount | null
  revenueYoyGrowthPct: number | null
  grossMargin: FinancialResultAmount | null
  ebitda: FinancialResultAmount | null
  ebit: FinancialResultAmount | null
  consolidatedNetIncome: FinancialResultAmount | null
  consolidatedNetIncomeYoyGrowthPct: number | null
  earningsPerShare: FinancialResultAmount | null
  earningsPerShareYoyGrowthPct: number | null
  freeCashFlow: FinancialResultAmount | null
  freeCashFlowYoyGrowthPct: number | null
  cashConversionCycle: FinancialResultAmount | null
  totalAssets: FinancialResultAmount | null
  capex: FinancialResultAmount | null
  debt: FinancialResultAmount | null
  cash: FinancialResultAmount | null
  netDebt: FinancialResultAmount | null
  inventory: FinancialResultAmount | null
  receivables: FinancialResultAmount | null
  payables: FinancialResultAmount | null
  capital: FinancialResultAmount | null
  currentLiabilities: FinancialResultAmount | null
  totalEquity: FinancialResultAmount | null
  fte: FinancialResultAmount | null
  fteGrowthPct: FinancialResultAmount | null
  revenueFteRatio: number | null
  netDebtEbitdaRatio: number | null
  ebitdaMinusCapex: number | null
  returnOnAssets: number | null
  returnOnEquity: number | null
  returnOnCapitalEmployed: number | null
}

export interface AssetFteMeasurement {
  readonly id: number
  readonly assetId: number
  employeeCount: number
  determinedAt: string
}

export interface AssetGeneralInfo {
  readonly assetId: number
  name: string
  ownership: Shared.AssetOwnershipType
  ownershipIsVerified: boolean
  logoFileId: number | null
  readonly logoFileUrl: string | null
  readonly logoFilename: string | null
  url: string | null
  lastDealYear: number | null
  lastDealMonth: number | null
  headquarters: string
  sector: string
  subsector: string
  customerBase: Shared.AssetCustomerBaseType[]
  businessActivity: Shared.AssetBusinessActivityType[]
  salesChannel: Shared.AssetSalesChannelType[]
  pricePositioning: Shared.AssetPricePositioningType | null
  headquartersAddressId: number | null
  headquartersAddress: Address | null
  fte: number | null
  fteYear: number | null
  fteRange: string | null
  currency: string | null
  excelFileId: number | null
  excelFileName: string | null
  esgOutperformer: boolean
  totalFunding: number | null
  totalFundingCurrency: string | null
  yearFounded: number | null
  webUrl: string | null
  linkedinExternalId: string | null
}

export interface AssetLatestFinancials {
  readonly assetId: number
  revenue: FinancialLatestResultAmount | null
  grossMargin: FinancialLatestResultAmount | null
  ebitda: FinancialLatestResultAmount | null
  ebit: FinancialLatestResultAmount | null
  consolidatedNetIncome: FinancialLatestResultAmount | null
  earningsPerShare: FinancialLatestResultAmount | null
  freeCashFlow: FinancialLatestResultAmount | null
  cashConversionCycle: FinancialLatestResultAmount | null
  totalAssets: FinancialLatestResultAmount | null
  capex: FinancialLatestResultAmount | null
  debt: FinancialLatestResultAmount | null
  netDebt: FinancialLatestResultAmount | null
  cash: FinancialLatestResultAmount | null
  capital: FinancialLatestResultAmount | null
  inventory: FinancialLatestResultAmount | null
  receivables: FinancialLatestResultAmount | null
  payables: FinancialLatestResultAmount | null
  fte: FinancialLatestResultAmount | null
}

export interface AssetLegalEntity {
  readonly id: number
  assetId: number
  legalEntityId: number
  financials: boolean
  financialsUntilYear: number | null
  isAutomatedAsset: boolean
  order: number
}

export interface AssetList {
  items: AssetListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface AssetListItem {
  readonly id: number
  assetLive: boolean
  profileLive: boolean
  profileType: Shared.AssetProfileType
  readonly publishedAt: string | null
  readonly updatedAt: string | null
  readonly financialsAt: string | null
  description: string | null
  aliases: string[]
  tags: string[]
  tagIds: number[]
  name: string
  sector: string | null
  subsector: string | null
  region: string | null
  url: string | null
  yearFounded: number | null
  currency: string | null
  currencyToEur: number | null
  businessActivity: Shared.AssetBusinessActivityType[]
  customerBase: Shared.AssetCustomerBaseType[]
  pricePositioning: Shared.AssetPricePositioningType | null
  salesChannel: Shared.AssetSalesChannelType[]
  headquartersWgs84LngLat: Point | null
  headquartersFormattedAddress: string | null
  headquartersCountryCode: string | null
  headquartersRegion: string | null
  headquartersCity: string | null
  lastDealYear: number | null
  lastDealMonth: number | null
  yearsSinceLastDeal: number | null
  esgOutperformer: boolean
  linkedinExternalId: string | null
  logoFileId: number | null
  logoFileUrl: string | null
  previousExcelFileId: number | null
  webUrl: string | null
  domain: string | null
  revenueResults: number[]
  revenueYears: number[]
  grossMarginResults: number[]
  grossMarginYears: number[]
  grossMarginPctRevenueResults: number[]
  grossMarginPctRevenueYears: number[]
  ebitdaResults: number[]
  ebitdaYears: number[]
  ebitdaPctRevenueResults: number[]
  ebitdaPctRevenueYears: number[]
  ebitResults: number[]
  ebitYears: number[]
  ebitPctRevenueResults: number[]
  ebitPctRevenueYears: number[]
  consolidatedNetIncomeResults: number[]
  consolidatedNetIncomeYears: number[]
  earningsPerShareResults: number[]
  earningsPerShareYears: number[]
  cashConversionCycleResults: number[]
  cashConversionCycleYears: number[]
  freeCashFlowResults: number[]
  freeCashFlowYears: number[]
  totalAssetsResults: number[]
  totalAssetsYears: number[]
  debtResults: number[]
  debtYears: number[]
  netDebtResults: number[]
  netDebtYears: number[]
  cashResults: number[]
  cashYears: number[]
  capitalResults: number[]
  capitalYears: number[]
  inventoryResults: number[]
  inventoryYears: number[]
  receivablesResults: number[]
  receivablesYears: number[]
  payablesResults: number[]
  payablesYears: number[]
  capexResults: number[]
  capexYears: number[]
  financialResults: AssetSummaryFinancialResult[]
  fteResults: number[]
  fteYears: number[]
  fte: number | null
  fteYear: number | null
  fteRange: string | null
  fteRangeCategory: number | null
  revenue: number | null
  revenueEur: number | null
  revenueWithAiGenerated: number | null
  revenueWithAiGeneratedEur: number | null
  revenueIsAiGenerated: boolean
  revenueYear: number | null
  grossMargin: number | null
  grossMarginEur: number | null
  grossMarginYear: number | null
  grossMarginPctRevenue: number | null
  ebitda: number | null
  ebitdaEur: number | null
  ebitdaYear: number | null
  ebitdaPctRevenue: number | null
  ebit: number | null
  ebitEur: number | null
  ebitYear: number | null
  ebitPctRevenue: number | null
  consolidatedNetIncome: number | null
  consolidatedNetIncomeEur: number | null
  consolidatedNetIncomeYear: number | null
  earningsPerShare: number | null
  earningsPerShareEur: number | null
  earningsPerShareYear: number | null
  cashConversionCycle: number | null
  cashConversionCycleYear: number | null
  freeCashFlow: number | null
  freeCashFlowEur: number | null
  freeCashFlowYear: number | null
  totalAssets: number | null
  totalAssetsEur: number | null
  totalAssetsYear: number | null
  debt: number | null
  debtEur: number | null
  debtYear: number | null
  netDebt: number | null
  netDebtEur: number | null
  netDebtYear: number | null
  cash: number | null
  cashEur: number | null
  cashYear: number | null
  capital: number | null
  capitalEur: number | null
  capitalYear: number | null
  companyAge: number | null
  inventory: number | null
  inventoryEur: number | null
  inventoryYear: number | null
  receivables: number | null
  receivablesEur: number | null
  receivablesYear: number | null
  payables: number | null
  payablesEur: number | null
  payablesYear: number | null
  capex: number | null
  capexEur: number | null
  capexYear: number | null
  growthMetrics: GrowthMetric[]
  revenueGrowthPctOneYear: number | null
  revenueCagrPctTwoYears: number | null
  revenueCagrPctThreeYears: number | null
  grossMarginGrowthPctOneYear: number | null
  grossMarginCagrPctTwoYears: number | null
  grossMarginCagrPctThreeYears: number | null
  ebitdaGrowthPctOneYear: number | null
  ebitdaCagrPctTwoYears: number | null
  ebitdaCagrPctThreeYears: number | null
  ebitGrowthPctOneYear: number | null
  ebitCagrPctTwoYears: number | null
  ebitCagrPctThreeYears: number | null
  fteGrowthPctThreeMonths: number | null
  fteGrowthPctSixMonths: number | null
  fteGrowthPctOneYear: number | null
  fteCagrPctTwoYears: number | null
  fteCagrPctThreeYears: number | null
  revenueFteRatio: number | null
  revenueFteRatioEur: number | null
  revenueFteRatioYear: number | null
  netDebtEbitdaRatio: number | null
  netDebtEbitdaRatioYear: number | null
  ebitdaMinusCapex: number | null
  ebitdaMinusCapexEur: number | null
  ebitdaMinusCapexYear: number | null
  returnOnAssets: number | null
  returnOnAssetsYear: number | null
  returnOnEquity: number | null
  returnOnEquityYear: number | null
  returnOnCapitalEmployed: number | null
  returnOnCapitalEmployedYear: number | null
  majorityOwnerId: number | null
  majorityOwnerLogoFileUrl: string | null
  majorityOwnerName: string | null
  majorityStrategyId: number | null
  majorityStrategyName: string | null
  ownership: Shared.AssetOwnershipType | null
  ownershipIsVerified: boolean
  ownerIds: number[]
  ownerLogoFileUrls: string[]
  ownerNames: string[]
  ownerShares: Shared.AssetShareholderShare[]
  strategyIds: number[]
  strategyNames: string[]
  legalEntityNames: string[]
  legalEntityExternalIds: string[]
  legalEntityRegions: string[]
  ceoAge: number | null
  ceoName: string | null
  ceoTenure: number | null
  subsidiaryPath: number[] | null
  advisorIds: number[]
  predictedExit: boolean
  predictedExitYear: number | null
  predictedExitEbitda: number | null
  predictedExitMultiple: number | null
  predictedExitEv: number | null
  predictedExitEvEur: number | null
  predictedExitEbitdaEur: number | null
  viewOnValuation: boolean
  legalEntities: AssetSummaryLegalEntity[]
  investors: AssetSummaryInvestor[]
  latestDealPreMoneyValuationEur: number | null
  latestDealPreMoneyValuationYear: number | null
  latestDealPostMoneyValuationEur: number | null
  latestDealPostMoneyValuationYear: number | null
  latestDealRoundType: Shared.DealFundingRoundType | null
  latestDealRoundSizeEur: number | null
  latestDealRoundYear: number | null
  totalDealFundingRaisedEur: number | null
  addOnDealCountL5Y: number | null
  addOnDealCountL3Y: number | null
  ratingGrowth: number | null
  ratingOrganicGrowth: number | null
  ratingNonCyclical: number | null
  ratingContracted: number | null
  ratingGrossMargin: number | null
  ratingEbitda: number | null
  ratingConversion: number | null
  ratingLeader: number | null
  ratingBuyAndBuild: number | null
  ratingMultinational: number | null
  ratingOverall: number | null
  latestIndustryRatingOverall: number | null
  latestIndustryRatingEnvironmental: number | null
  latestIndustryRatingSocial: number | null
  valuationRatios: AssetSummaryValuationRatio[]
  enterpriseValueRevenueRatioLastFiscalYear: number | null
  enterpriseValueEbitdaRatioLastFiscalYear: number | null
  enterpriseValueEbitRatioLastFiscalYear: number | null
  enterpriseValueRevenueRatioLastTwelveMonths: number | null
  enterpriseValueEbitdaRatioLastTwelveMonths: number | null
  enterpriseValueEbitRatioLastTwelveMonths: number | null
  enterpriseValueRevenueRatioCurrentFiscalYear: number | null
  enterpriseValueEbitdaRatioCurrentFiscalYear: number | null
  enterpriseValueEbitRatioCurrentFiscalYear: number | null
  enterpriseValueRevenueRatioNextTwelveMonths: number | null
  enterpriseValueEbitdaRatioNextTwelveMonths: number | null
  enterpriseValueEbitRatioNextTwelveMonths: number | null
  enterpriseValueRevenueRatioNextFiscalYear: number | null
  enterpriseValueEbitdaRatioNextFiscalYear: number | null
  enterpriseValueEbitRatioNextFiscalYear: number | null
  marketCapitalization: number | null
  marketCapitalizationEur: number | null
  marketCapitalizationFiscalYear: number | null
  marketCapitalizationFiscalYearQuarter: number | null
  enterpriseValue: number | null
  enterpriseValueEur: number | null
  enterpriseValueFiscalYear: number | null
  enterpriseValueFiscalYearQuarter: number | null
  enterpriseValueRevenueRatio: number | null
  enterpriseValueRevenueRatioFiscalYear: number | null
  enterpriseValueRevenueRatioFiscalYearQuarter: number | null
  enterpriseValueEbitdaRatio: number | null
  enterpriseValueEbitdaRatioFiscalYear: number | null
  enterpriseValueEbitdaRatioFiscalYearQuarter: number | null
  enterpriseValueEbitdaMinusCapexRatio: number | null
  enterpriseValueEbitdaMinusCapexRatioFiscalYear: number | null
  enterpriseValueEbitdaMinusCapexRatioFiscalYearQuarter: number | null
  enterpriseValueEbitRatio: number | null
  enterpriseValueEbitRatioFiscalYear: number | null
  enterpriseValueEbitRatioFiscalYearQuarter: number | null
  enterpriseValueInvestedCapitalRatio: number | null
  enterpriseValueInvestedCapitalRatioFiscalYear: number | null
  enterpriseValueInvestedCapitalRatioFiscalYearQuarter: number | null
  enterpriseValueFreeCashFlowRatio: number | null
  enterpriseValueFreeCashFlowRatioFiscalYear: number | null
  enterpriseValueFreeCashFlowRatioFiscalYearQuarter: number | null
  readonly gainProUrl: string
  previousFactsheetFileId: number | null
  matchingTagIds: number[]
  matchingTagsCount: number
  relevanceRank: number
  ftsRelevance: number | null
  ftsHeadline: string | null
}

export interface AssetListedSecurity {
  readonly id: number
  assetId: number
  listedSecurityId: number
}

export interface AssetManager {
  readonly assetId: number
  readonly id: number
  personId: number | null
  readonly createdAt: string
  manuallyCreated: boolean
  status: Shared.ManagerStatusType
  managementPosition: Shared.ManagementPositionType | null
  joinYear: number | null
  joinMonth: number | null
  relevanceOrder: number
  readonly person: Person | null
  readonly leaveYear: number | null
  readonly leaveMonth: number | null
  readonly position: string | null
  order: number
}

export interface AssetMarket {
  readonly assetId: number
  size: string
  trends: string
  competition: string
  cagr: number | null
  cagrMedian: number | null
  cagrStart: number | null
  cagrEnd: number | null
  cagrSource: string | null
}

export interface AssetNextDealPrediction {
  readonly assetId: number
  year: number
  viewOnValuation: boolean
}

export interface AssetOwnershipCount {
  count: number
  type: Shared.AssetOwnershipType
}

export interface AssetPro {
  readonly id: number
  readonly assetId: number
  text: string
  order: number
}

export interface AssetRating {
  readonly assetId: number
  growth: number | null
  organicGrowth: number | null
  nonCyclical: number | null
  contracted: number | null
  grossMargin: number | null
  ebitda: number | null
  conversion: number | null
  leader: number | null
  buyAndBuild: number | null
  multinational: number | null
  overall: number | null
}

export interface AssetSegment {
  readonly id: number
  assetSegmentationId: number
  title: string
  subtitle: string
  description: string
  order: number
}

export interface AssetSegmentation {
  readonly assetId: number
  title: string
  subtitle: string
  segments: AssetSegment[]
  charts: AssetChart[]
}

export interface AssetShareholder {
  readonly id: number
  readonly assetId: number
  investorId: number
  strategyId: number | null
  fundId: number | null
  fundConfidence: Shared.InvestorFundConfidence | null
  isCurrentInvestor: boolean
  share: Shared.AssetShareholderShare
  order: number
}

export interface AssetSource {
  readonly id: number
  readonly assetId: number
  title: string
  language: string | null
  publisher: string | null
  publicationYear: number | null
  publicationMonth: number | null
  url: string | null
  financials: boolean
  business: boolean
  market: boolean
  background: boolean
  order: number
}

export interface AssetSummaryFinancialResult {
  year: number
  periodicity: Shared.FinancialResultPeriodicityType
  isForecast: boolean
  capex: number | null
  capital: number | null
  cash: number | null
  cashConversionCycle: number | null
  consolidatedNetIncome: number | null
  debt: number | null
  earningsPerShare: number | null
  ebit: number | null
  ebitda: number | null
  freeCashFlow: number | null
  fte: number | null
  grossMargin: number | null
  inventory: number | null
  netDebt: number | null
  payables: number | null
  receivables: number | null
  revenue: number | null
  totalAssets: number | null
}

export interface AssetSummaryInvestor {
  id: number
  name: string
  share: Shared.AssetShareholderShare
  strategyId: number | null
  strategyName: string | null
}

export interface AssetSummaryLegalEntity {
  name: string
  region: string
  externalId: string
}

export interface AssetSummaryValuationRatio {
  period: AssetSummaryValuationPeriod
  enterpriseValueRevenueRatio: number | null
  enterpriseValueEbitdaRatio: number | null
  enterpriseValueEbitdaMinusCapexRatio: number | null
  enterpriseValueEbitRatio: number | null
  enterpriseValueInvestedCapitalRatio: number | null
  enterpriseValueFreeCashFlowRatio: number | null
}

export interface AssetTag {
  readonly id: number
  readonly assetId: number
  tagId: number
  tag: string
}

export interface AssociatedTag {
  id: number
  name: string
  assetCount: number
}

export interface BeamerPost {
  id: number
  date: string
  dueDate: string
  published: boolean
  pinned: boolean
  showInWidget: boolean
  showInStandalone: boolean
  category: string
  boostedAnnouncement: string
  translations: BeamerTranslation[]
  filter: string
  filterUrl: string
  autoOpen: boolean
  editionDate: string
  feedbackEnabled: boolean
  reactionsEnabled: boolean
}

export interface Competitor {
  id: number
  linkedAssetId: number | null
  readonly linkedAssetName: string | null
  readonly usedByAssetIds: number[]
  name: string
  headquarters: string | null
  revenue: number | null
  revenueYear: number | null
  currency: string | null
  useGeneralInfoFromAsset: boolean
  useRevenueFromAsset: boolean
}

export interface CompetitorList {
  items: CompetitorListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface CompetitorListItem {
  linkedAssetId: number
  id: number
  name: string | null
  description: string | null
  headquarters: string | null
  revenue: number | null
  revenueEur: number | null
  revenueYear: number | null
  currency: string | null
  linkedAssetLogoFileUrl: string | null
}

export interface ConferenceEditionExhibitorSummary {
  assetId: number | null
  name: string
  url: string | null
}

export interface ConferenceEditionList {
  items: ConferenceEditionListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface ConferenceEditionListItem {
  readonly id: number
  name: string
  readonly live: boolean
  logoFileId: number | null
  readonly logoFileUrl: string | null
  startDate: string
  endDate: string
  url: string | null
  webUrl: string | null
  venueWgs84LngLat: Point | null
  venueFormattedAddress: string
  venueCountryCode: string
  venueRegion: string | null
  venueCity: string
  exhibitorsScrapedAt: string | null
  exhibitorsCount: number
  exhibitorsLinkedCount: number
  exhibitorTags: string[]
  exhibitorTagIds: number[]
  exhibitorTagRatios: number[]
  exhibitorTagAssetCount: number[]
  updatedAt: string
  readonly exhibitors: ConferenceEditionExhibitorSummary[]
  readonly gainProUrl: string
}

export interface CurrencyList {
  items: CurrencyListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface CurrencyListItem {
  id: number
  name: string
  title: string
  symbol: string
  updatedAt: string | null
  display: Shared.CurrencyDisplayType
  toEur: number
  allowUserSelect: boolean
}

export interface CustomAssetList {
  readonly id: number
  readonly type: CustomAssetListEnum
  title: string
  description: string
  ownerUserId: number
  readonly lastViewedAt: string | null
  readonly createdAt: string
  assetIds: number[]
  userIds: number[]
  readonly totalAssetCount: number
  readonly updatedAssetCount: number
}

export interface CustomAssetQueryFilter {
  field: string
  operator: string
  value: any
}

export interface CustomAssetQueryList {
  items: CustomAssetQueryListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface CustomAssetQueryListItem {
  readonly id: number
  readonly ownerUserId: number
  title: string
  description: string
  shared: boolean
  currency: string
  exchangeRate: number
  isRecentlyFiltered: boolean
  readonly createdAt: string
  readonly updatedAt: string
  search: string
  filters: CustomAssetQueryFilter[]
  readonly users: CustomAssetQueryUser[]
  readonly type: CustomAssetListEnum
  readonly lastViewedAt: string | null
  readonly totalAssetCount: number
  readonly userIds: number[]
  readonly updatedAssetCount: number
}

export interface CustomAssetQueryUser {
  userId: number
  readonly addedAt: string
  readonly addedByUserId: number | null
  order: number
}

export interface CustomAssetRatings {
  growth: number | null
  organicGrowth: number | null
  grossMargin: number | null
  ebitda: number | null
  conversion: number | null
  nonCyclical: number | null
  contracted: number | null
  leader: number | null
  multinational: number | null
  buyAndBuild: number | null
  environmental: number | null
  social: number | null
  overall: number | null
}

export interface Date {
  year: number
  month: number | null
  day: number | null
}

export interface Deal {
  readonly id: number
  readonly status: Shared.DealItemStatus
  readonly publicationDate: string | null
  asset: string | null
  region: string | null
  sector: string | null
  subsector: string | null
  division: string | null
  reasons: Shared.DealReason[]
  dealStatus: Shared.DealStatus | null
  announcementDate: Date | null
  currency: string | null
  linkedAssetId: number | null
  businessActivities: string | null
  fte: DealFactFloat | null
  ev: DealFactFloat | null
  revenue: DealFactFloat | null
  ebitda: DealFactFloat | null
  ebit: DealFactFloat | null
  totalAssets: DealFactFloat | null
  evEbitdaMultiple: DealFactFloat | null
  evEbitMultiple: DealFactFloat | null
  evRevenueMultiple: DealFactFloat | null
  evTotalAssetsMultiple: DealFactFloat | null
  equity: DealFactFloat | null
  fundingRoundAmountRaised: DealFactFloat | null
  fundingRoundPreMoneyValuation: DealFactFloat | null
  fundingRoundPostMoneyValuation: DealFactFloat | null
  fundingRoundType: Shared.DealFundingRoundType | null
  readonly highlightedBuyerId: number | null
  readonly highlightedSellerId: number | null
  buyers: DealBuyer[]
  sellers: DealSeller[]
  sources: DealSource[]
  notes: DealNote[]
  dealType: Shared.DealType | null
  dealroomFundingId: number | null
  advisors: DealAdvisor[]
}

export interface DealAdvisor {
  readonly id: number
  readonly dealId: number
  advisorId: number | null
  advised: Shared.DealAdvisorAdvised
  advisedOn: Shared.AdvisoryActivity[]
}

export interface DealAdvisorAsset {
  id: number | null
  name: string
  logoFileUrl: string | null
}

export interface DealBuyer {
  order: number
  type: Shared.DealSideType
  reason: Shared.DealReason | null
  name: string | null
  division: string | null
  region: string | null
  linkedInvestorId: number | null
  linkedStrategyId: number | null
  linkedFundId: number | null
  linkedFundConfidence: Shared.InvestorFundConfidence | null
  linkedAssetId: number | null
  share: DealFactSideShare | null
  sharePct: DealFactFloat | null
  leadingParty: boolean
}

export interface DealCloudItem {
  status: DealCloudStatus
  error: string
  assetId: number
  entryId: number
  entryListId: number
  name: string
  url: string
}

export interface DealFactFloat {
  value: number
  confidence: Shared.DealFactConfidence
  date: Date | null
}

export interface DealFactSideShare {
  value: Shared.DealSideShare
  confidence: Shared.DealFactConfidence
  date: Date | null
}

export interface DealList {
  items: DealListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface DealListItem {
  readonly id: number
  publicationDate: string | null
  asset: string | null
  assetLogoFileUrl: string | null
  linkedAssetId: number | null
  linkedAssetDescription: string | null
  region: string | null
  sector: string | null
  subsector: string | null
  currency: string | null
  division: string | null
  dealStatus: Shared.DealStatus | null
  dealType: Shared.DealType | null
  reasons: Shared.DealReason[]
  announcementDate: string
  announcementDateYear: number | null
  announcementDateMonth: number | null
  tags: string[]
  tagIds: number[]
  investorIds: number[]
  advisorIds: number[]
  readonly live: boolean
  highlightedBuyerId: number | null
  highlightedBuyerType: Shared.DealSideType | null
  highlightedBuyerReason: Shared.DealReason | null
  highlightedBuyerName: string | null
  highlightedBuyerDivision: string | null
  highlightedBuyerRegion: string | null
  highlightedBuyerShare: Shared.DealSideShare | null
  highlightedBuyerSharePct: number | null
  highlightedSellerId: number | null
  highlightedSellerType: Shared.DealSideType | null
  highlightedSellerReason: Shared.DealReason | null
  highlightedSellerName: string | null
  highlightedSellerDivision: string | null
  highlightedSellerRegion: string | null
  highlightedSellerShare: Shared.DealSideShare | null
  highlightedSellerSharePct: number | null
  readonly buyersInfo: DealSummarySide[]
  readonly sellersInfo: DealSummarySide[]
  buyerAdvisorIds: number[]
  sellerAdvisorIds: number[]
  buyerAssetIds: number[]
  buyerInvestorIds: number[]
  buyerReasons: Shared.DealReason[]
  buyerShares: Shared.DealSideShare[]
  buyerSharePcts: number[]
  buyerLogoFileUrls: string[]
  sellerAssetIds: number[]
  sellerInvestorIds: number[]
  sellerReasons: Shared.DealReason[]
  sellerShares: Shared.DealSideShare[]
  sellerSharePcts: number[]
  sellerLogoFileUrls: string[]
  buyers: number
  buyerAssetNames: string[]
  buyerInvestorNames: string[]
  buyerNames: string[]
  buyerTypes: Shared.DealSideType[]
  buyerLeadingParties: boolean[]
  buyerLinkedIds: number[]
  sellers: number
  sellerAssetNames: string[]
  sellerInvestorNames: string[]
  sellerNames: string[]
  sellerTypes: Shared.DealSideType[]
  sellerLeadingParties: boolean[]
  sellerLinkedIds: number[]
  fte: number | null
  fteYear: number | null
  revenue: number | null
  revenueEur: number | null
  revenueYear: number | null
  ebitda: number | null
  ebitdaEur: number | null
  ebitdaYear: number | null
  ebit: number | null
  ebitEur: number | null
  ebitYear: number | null
  totalAssets: number | null
  totalAssetsEur: number | null
  totalAssetsYear: number | null
  ev: number | null
  evEur: number | null
  evYear: number | null
  equity: number | null
  equityEur: number | null
  equityYear: number | null
  evEbitdaMultiple: number | null
  evEbitdaMultipleYear: number | null
  evEbitMultiple: number | null
  evEbitMultipleYear: number | null
  evRevenueMultiple: number | null
  evRevenueMultipleYear: number | null
  evTotalAssetsMultiple: number | null
  evTotalAssetsMultipleYear: number | null
  fundingRoundAmountRaised: number | null
  fundingRoundAmountRaisedEur: number | null
  fundingRoundAmountRaisedYear: number | null
  fundingRoundPreMoneyValuation: number | null
  fundingRoundPreMoneyValuationEur: number | null
  fundingRoundPreMoneyValuationYear: number | null
  fundingRoundPostMoneyValuation: number | null
  fundingRoundPostMoneyValuationEur: number | null
  fundingRoundPostMoneyValuationYear: number | null
  fundingRoundType: Shared.DealFundingRoundType | null
  readonly assetGainProUrl: string | null
  readonly gainProUrl: string
  readonly assetWebUrl: string | null
  readonly webUrl: string
}

export interface DealNote {
  order: number
  text: string
}

export interface DealSeller {
  order: number
  type: Shared.DealSideType
  reason: Shared.DealReason | null
  name: string | null
  division: string | null
  region: string | null
  linkedInvestorId: number | null
  linkedStrategyId: number | null
  linkedFundId: number | null
  linkedFundConfidence: Shared.InvestorFundConfidence | null
  linkedAssetId: number | null
  share: DealFactSideShare | null
  sharePct: DealFactFloat | null
  leadingParty: boolean
}

export interface DealSource {
  order: number
  title: string
  language: string | null
  publisher: string | null
  publicationYear: number | null
  publicationMonth: number | null
  url: string | null
}

export interface DealSummarySide {
  name: string | null
  type: Shared.DealSideType
  share: DealFactSideShare | null
  reason: Shared.DealReason | null
  linkedId: number | null
  sharePct: DealFactFloat | null
  leadingParty: boolean
}

export interface DealsPerTypeByYear {
  dealCount: number
  year: number
  type: string
}

export interface EnumInfo {
  name: string
  summary: string
  description: string
  type: string
  values: Value[]
}

export interface ErrorInfo {
  code: number
  description: string
}

export interface Filter {
  field: string
  operator: string
  value: any
}

export interface FinancialLatestResultAmount {
  year: number
  amount: number
  amountType: Shared.FinancialResultAmountType
  readonly pctRevenue: number | null
  estimated: boolean
}

export interface FinancialResultAmount {
  amount: number
  amountType: Shared.FinancialResultAmountType
  readonly pctRevenue: number | null
  estimated: boolean
}

export interface GeoJSON {
  type: string
  coordinates: number[][][][]
}

export interface GeoPolygon {
  readonly id: number
  placeId: string
  placeIdBoundary: string
  name: string | null
  nameEn: string | null
  country: string
  countryCode: string
  state: string | null
  stateCode: string | null
  county: string | null
  city: string | null
  suburb: string | null
  postcode: string | null
  formatted: string
  lng: number
  lat: number
  geom: MultiPolygon | null
  geoJson: GeoJSON
}

export interface GetAuthenticationTypeResponse {
  type: AuthenticationType
  url: string
}

export interface GetCityByPlaceIdResponse {
  city: string
  region: string | null
  countryCode: string
}

export interface GetEmailAddressResult {
  email: string
  confidence: number
}

export interface GetGetCustomAssetListUrlImportTaskStateResult {
  readonly id: number
  readonly customAssetListId: number
  readonly urlsChecked: number
  readonly urlsMatched: number
  readonly urlsTotal: number
  readonly duplicateMatchCount: number
  readonly resultFileUrl: string | null
  readonly createdAt: string
  readonly updatedAt: string
  readonly completedAt: string | null
  hasError: boolean
}

export interface GetLonLatByPlaceIdResponse {
  lng: number
  lat: number
}

export interface GlobalUltimateOwner {
  readonly id: number
  name: string
  region: string
  webUrl: string | null
  linkedLegalEntityId: number | null
  readonly linkedLegalEntityName: string | null
  readonly linkedLegalEntityRegion: string | null
  readonly subsidiaries: GlobalUltimateOwnerSubsidiary[]
}

export interface GlobalUltimateOwnerSubsidiary {
  legalEntityId: number
  name: string
  region: string
  revenueEur: number | null
  revenueYear: number | null
  ebitdaEur: number | null
  ebitdaYear: number | null
}

export interface GrowthMetric {
  period: string
  revenueGrowth: number | null
  grossMarginGrowth: number | null
  ebitdaGrowth: number | null
  ebitGrowth: number | null
  fteGrowth: number | null
}

export interface Industry {
  readonly id: number
  readonly status: Shared.IndustryStatus
  readonly publicationDate: string | null
  generalInfo: IndustryGeneralInfo | null
  charts: IndustryChart[]
  keyTakeaways: IndustryKeyTakeaway[]
  assets: IndustryAsset[]
  market: IndustryMarket | null
  outlook: IndustryOutlook | null
  deals: IndustryDeals | null
  pros: IndustryPro[]
  cons: IndustryCon[]
  sources: IndustrySource[]
  factSheetFileCreatedAt: string | null
  factSheetFileId: number | null
}

export interface IndustryAsset {
  order: number
  assetId: number
  segmentId: number | null
}

export interface IndustryChart {
  order: number
  type: Shared.IndustryChartType | null
  fileId: number | null
  readonly filename: string | null
  readonly fileUrl: string | null
}

export interface IndustryCon {
  order: number
  text: string
}

export interface IndustryDeals {
  recentMAndA: string | null
  assetAvailability: string | null
}

export interface IndustryGeneralInfo {
  date: string | null
  title: string
  sector: string | null
  subsector: string | null
  regions: string[]
  tags: string[]
  smallImageFileId: number | null
  readonly smallImageFilename: string | null
  readonly smallImageFileUrl: string | null
  largeImageFileId: number | null
  readonly largeImageFilename: string | null
  readonly largeImageFileUrl: string | null
  emailImageFileId: number | null
  readonly emailImageFilename: string | null
  readonly emailImageFileUrl: string | null
  coverImageFileId: number | null
  readonly coverImageFilename: string | null
  readonly coverImageFileUrl: string | null
  scope: string | null
}

export interface IndustryKeyTakeaway {
  order: number
  text: string
}

export interface IndustryList {
  items: IndustryListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface IndustryListItem {
  id: number
  publicationDate: string | null
  date: string | null
  title: string | null
  sector: string | null
  subsector: string | null
  scope: string | null
  regions: string[]
  linkedAssetIds: number[]
  linkedAssetsTotal: number
  smallImageFileId: number | null
  smallImageFileUrl: string | null
  largeImageFileId: number | null
  largeImageFileUrl: string | null
  ratingMedianOrganicGrowth: number | null
  ratingMedianEbitda: number | null
  ratingMedianOverall: number | null
  ratingMedianEnvironmental: number | null
  ratingMedianSocial: number | null
}

export interface IndustryMarket {
  definition: string | null
  structure: string | null
  segments: IndustryMarketSegment[]
  esg: string | null
}

export interface IndustryMarketSegment {
  id: number
  order: number
  name: string
  text: string
  ratingEnvironmental: number | null
  ratingSocial: number | null
  ratingOverall: number | null
}

export interface IndustryMarketSegmentList {
  items: IndustryMarketSegmentListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface IndustryMarketSegmentListItem {
  id: number
  name: string | null
  text: string | null
  industryPublicationDate: string | null
  industryStatus: Shared.IndustryStatus
  industryId: number
  industryDate: string | null
  industryTitle: string | null
  industrySmallImageFileUrl: string | null
  industryRegions: string[]
  industryScope: string | null
  industrySubsector: string | null
  linkedAssetIds: number[]
  linkedAssetsTotal: number
  totalRevenueEur: number | null
  dealCount: number
  ratingMedianGrowth: number | null
  ratingMedianOrganicGrowth: number | null
  ratingMedianGrossMargin: number | null
  ratingMedianEbitda: number | null
  ratingMedianConversion: number | null
  ratingMedianNonCyclical: number | null
  ratingMedianContracted: number | null
  ratingMedianLeader: number | null
  ratingMedianMultinational: number | null
  ratingMedianBuyAndBuild: number | null
  ratingEnvironmental: number | null
  ratingSocial: number | null
  ratingOverall: number | null
}

export interface IndustryOutlook {
  sizeAndGrowth: IndustryOutlookSizeAndGrowth[]
  positiveDrivers: IndustryOutlookPositiveDriver[]
  negativeDrivers: IndustryOutlookNegativeDriver[]
}

export interface IndustryOutlookNegativeDriver {
  order: number
  text: string
}

export interface IndustryOutlookPositiveDriver {
  order: number
  text: string
}

export interface IndustryOutlookSizeAndGrowth {
  order: number
  text: string
}

export interface IndustryPro {
  order: number
  text: string
}

export interface IndustrySegmentDealByYear {
  dealCount: number
  medianEvRevenueMultiple: number | null
  medianEvEbitdaMultiple: number | null
  medianEvEbitMultiple: number | null
  medianEvTotalAssetsMultiple: number | null
  year: number
  industryMarketSegmentId: number
  industryMarketSegmentName: string
}

export interface IndustrySource {
  order: number
  title: string | null
  language: string | null
  publisher: string | null
  publicationYear: number | null
  publicationMonth: number | null
  url: string | null
  segment: boolean | null
  outlook: boolean | null
  assessment: boolean | null
  deals: boolean | null
  esg: boolean | null
}

export interface Investor {
  readonly id: number
  readonly live: boolean
  readonly createdAt: string
  readonly updatedAt: string
  readonly publicationDate: string | null
  readonly unpublishedAt: string | null
  name: string
  shortName: string | null
  description: string | null
  url: string | null
  logoFileId: number | null
  readonly logoFileUrl: string | null
  readonly logoFilename: string | null
  readonly liveFundsCount: number
  readonly liveFundSizeEur: number | null
  readonly dryPowderMinEur: number | null
  readonly dryPowderMaxEur: number | null
  readonly fte: number | null
  strategies: InvestorStrategy[]
  funds: InvestorFund[]
  aliases: InvestorAlias[]
  fteMeasurements: InvestorFteMeasurement[]
  managers: InvestorManager[]
  flagshipFundId: number | null
  latestFundId: number | null
  fundsRaisedLastFiveYears: number | null
  operationalHqCity: string | null
  operationalHqCountryCode: string | null
  yearFounded: number | null
  foundedBy: string | null
  linkedInUrl: string | null
  linkedinExternalId: string | null
  onlyIncludeCurated: boolean
}

export interface InvestorAlias {
  readonly id: number
  readonly investorId: number
  alias: string
  order: number
}

export interface InvestorDealsPerTypeByYear {
  dealCount: number
  year: number
  type: string
}

export interface InvestorFteMeasurement {
  readonly id: number
  readonly investorId: number
  employeeCount: number
  determinedAt: string
}

export interface InvestorFund {
  id: number
  name: string
  fundraisingStatus: Shared.InvestorFundFundraisingStatus | null
  fundSize: number | null
  readonly fundSizeEur: number | null
  targetFundSize: number | null
  firstCloseSize: number | null
  hardCapSize: number | null
  currency: string
  vintageDate: Date | null
  launchDate: Date | null
  firstCloseDate: Date | null
  finalCloseDate: Date | null
  order: number
  source: string
  continuationFund: boolean
  singleDealFund: boolean
  notes: InvestorFundNote[]
  dryPowderMin: number | null
  dryPowderMax: number | null
  investorId: number
  investorStrategyId: number | null
  netIrr: number | null
  netIrrDate: string | null
  grossIrr: number | null
  grossIrrDate: string | null
  twr: number | null
  twrDate: string | null
  tvpi: number | null
  tvpiDate: string | null
  moic: number | null
  moicDate: string | null
  dpi: number | null
  dpiDate: string | null
  rvpi: number | null
  rvpiDate: string | null
}

export interface InvestorFundList {
  items: InvestorFundListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface InvestorFundListItem {
  id: number
  name: string
  fundSizeEur: number | null
  vintageDate: string | null
  vintageMonth: number | null
  vintageYear: number | null
  netIrr: number | null
  netIrrDate: string | null
  grossIrr: number | null
  grossIrrDate: string | null
  twr: number | null
  twrDate: string | null
  tvpi: number | null
  tvpiDate: string | null
  moic: number | null
  moicDate: string | null
  dpi: number | null
  dpiDate: string | null
  rvpi: number | null
  rvpiDate: string | null
  investorId: number
  investorName: string
  investorLogoFileUrl: string | null
  strategyId: number | null
  strategyName: string | null
}

export interface InvestorFundNote {
  order: number
  text: string
}

export interface InvestorList {
  items: InvestorListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface InvestorListItem {
  readonly id: number
  name: string
  shortName: string | null
  readonly live: boolean
  aliases: string[]
  logoFileUrl: string | null
  logoFileId: number | null
  publicationDate: string | null
  url: string | null
  fte: number | null
  fteRange: string | null
  fteRangeCategory: number | null
  operationalHqCity: string | null
  operationalHqCountryCode: string | null
  onlyIncludeCurated: boolean
  advisorIds: number[]
  flagshipFundName: string | null
  flagshipFundSize: number | null
  flagshipFundSizeEur: number | null
  flagshipFundCurrency: string | null
  flagshipFundCurrencyToEur: number | null
  flagshipFundVintageDate: string | null
  flagshipFundVintageYear: number | null
  flagshipFundVintageMonth: number | null
  fundsRaisedLastFiveYears: number | null
  fundsRaisedLastFiveYearsEur: number | null
  liveFundsCount: number | null
  liveFundSizeEur: number | null
  dryPowderMinEur: number | null
  dryPowderMaxEur: number | null
  dealsTotalLastFiveYears: number | null
  dealsExitTotalLastFiveYears: number | null
  dealsAddOnsTotalLastFiveYears: number | null
  dealsEntriesTotalLastFiveYears: number | null
  assetIds: number[]
  assetEbitdaMedian: number | null
  assetEbitdaMedianEur: number | null
  assetEbitdas: number[]
  assetEbitdasEur: number[]
  assetRegions: string[]
  assetSectors: string[]
  assetSubsectors: string[]
  assetTags: string[]
  assetTagIds: number[]
  assetsTotal: number
  assetsMedianMarketSegmentRatingOverall: number | null
  assetsMedianMarketSegmentRatingEnvironmental: number | null
  assetsMedianMarketSegmentRatingSocial: number | null
  assetsFiltered: number
  readonly webUrl: string
  readonly gainProUrl: string
}

export interface InvestorManager {
  readonly investorId: number
  readonly id: number
  personId: number | null
  readonly createdAt: string
  manuallyCreated: boolean
  status: Shared.ManagerStatusType
  managementPosition: Shared.ManagementPositionType | null
  joinYear: number | null
  joinMonth: number | null
  relevanceOrder: number
  readonly person: Person | null
  readonly leaveYear: number | null
  readonly leaveMonth: number | null
  readonly position: string | null
  order: number
}

export interface InvestorProfileStrategy {
  id: number
  name: string
  latestFundName: string | null
  latestFundSizeEur: number | null
  latestFundVintageYear: number | null
  latestFundVintageMonth: number | null
  latestFundDryPowderMinEur: number | null
  latestFundDryPowderMaxEur: number | null
  primaryClassification: Shared.InvestorStrategyClassification | null
  secondaryClassification: Shared.InvestorStrategyClassification | null
  tertiaryClassification: Shared.InvestorStrategyClassification | null
  investmentTicketSizeMinEur: number | null
  investmentTicketSizeMaxEur: number | null
  evRangeMinEur: number | null
  evRangeMaxEur: number | null
  revenueRangeMinEur: number | null
  revenueRangeMaxEur: number | null
  ebitdaRangeMinEur: number | null
  ebitdaRangeMaxEur: number | null
  preferredEquityStakeMinPct: number | null
  preferredEquityStakeMaxPct: number | null
  preferredStake: Shared.InvestorStrategyPreferredStake
  assetCount: number
  assetIds: number[]
  assetEbitdaEurMedian: number
  assetRegions: string[]
  assetSectors: string[]
}

export interface InvestorStrategy {
  id: number
  name: string
  description: string
  order: number
  currency: string | null
  primaryClassification: Shared.InvestorStrategyClassification | null
  secondaryClassification: Shared.InvestorStrategyClassification | null
  tertiaryClassification: Shared.InvestorStrategyClassification | null
  investmentTicketSizeMin: number | null
  investmentTicketSizeMax: number | null
  evRangeMin: number | null
  evRangeMax: number | null
  revenueRangeMin: number | null
  revenueRangeMax: number | null
  ebitdaRangeMin: number | null
  ebitdaRangeMax: number | null
  preferredEquityStakeMinPct: number | null
  preferredEquityStakeMaxPct: number | null
  preferredStake: Shared.InvestorStrategyPreferredStake
  source: string | null
  investorId: number
  latestFundId: number | null
}

export interface InvestorStrategyList {
  items: InvestorStrategyListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface InvestorStrategyListItem {
  investorId: number
  investorName: string
  investorLogoFileUrl: string | null
  strategyId: number | null
  strategyName: string | null
  latestFundId: number | null
  latestFundName: string | null
  latestFundSize: number | null
  latestFundCurrency: string | null
  latestFundSizeEur: number | null
  latestFundVintageDate: string | null
  latestFundVintageDateYear: number | null
  latestFundVintageDateMonth: number | null
  latestFundVintageDateDay: number | null
  assetsTotal: number
  assetsFiltered: number
  assetIds: number[]
  assetEbitdas: number[]
  assetEbitdaMedian: number | null
  assetEbitdasEur: number[]
  assetEbitdaEurMedian: number | null
  assetRegions: string[]
  assetSectors: string[]
  assetSubsectors: string[]
  readonly webUrl: string
  readonly gainProUrl: string
}

export interface LegalEntity {
  readonly id: number
  name: string
  nameYear: number
  region: string
  externalId: string
  previousNames: string[]
  sector: string
  subsector: string
  currency: string
  automaticallyCreated: boolean
  linkedinCompanyId: number | null
  globalUltimateOwnerId: number | null
  shouldCheckFinancials: boolean
  annualReportsCheckedAt: string | null
  financialsUpdatedAt: string | null
  createdAt: string | null
  assets: AssetLegalEntity[]
  sectorCodes: LegalEntitySectorCode[]
  uRLs: LegalEntityUrl[]
  annualReports: AnnualReport[]
  financialResults: LegalEntityFinancialResult[]
  rating: LegalEntityRating | null
  readonly financialLatests: LegalEntityLatestFinancials | null
  listOfShareholders: ListOfShareholders[]
  legalEntityShareholders: LegalEntityShareholder[]
  tags: LegalEntityTag[]
}

export interface LegalEntityFinancialResult {
  readonly id: number
  readonly legalEntityId: number
  readonly profitAndLossFromAnnualReportId: number | null
  readonly balanceFromAnnualReportId: number | null
  readonly capexFromAnnualReportId: number | null
  readonly cashFlowFromAnnualReportId: number | null
  readonly notesOperatingProfitUKFromAnnualReportId: number | null
  year: number
  revenue: number | null
  revenueYoyGrowthPct: number | null
  grossMargin: number | null
  grossMarginPctRevenue: number | null
  ebitda: number | null
  ebitdaPctRevenue: number | null
  ebit: number | null
  ebitPctRevenue: number | null
  totalAssets: number | null
  capex: number | null
  debt: number | null
  cash: number | null
  netDebt: number | null
  inventory: number | null
  receivables: number | null
  payables: number | null
  capital: number | null
  fte: number | null
  fteGrowthPct: number | null
}

export interface LegalEntityLatestFinancials {
  readonly legalEntityId: number
  revenue: number | null
  revenueYear: number | null
  grossMargin: number | null
  grossMarginYear: number | null
  ebitda: number | null
  ebitdaYear: number | null
  ebitdaAvg: number | null
  ebitdaPctRevenue: number | null
  ebitdaPctRevenueAvg: number | null
  fte: number | null
  fteYear: number | null
  revenueYoyGrowthPctMin: number | null
  revenueYoyGrowthPctMax: number | null
  revenueResults: number[]
  revenueYears: number[]
  grossMarginResults: number[]
  grossMarginYears: number[]
  ebitdaResults: number[]
  ebitdaYears: number[]
  ebitdaPctRevenueResults: number[]
  ebitdaPctRevenueYears: number[]
  fteResults: number[]
  fteYears: number[]
}

export interface LegalEntityList {
  items: LegalEntityListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface LegalEntityListItem {
  readonly id: number
  name: string
  nameYear: number
  region: string
  sector: string
  subsector: string
  tags: string[]
  tagIds: number[]
  externalId: string
  readonly webUrl: string
  revenue: number | null
  revenueEur: number | null
  revenueYear: number | null
  grossMargin: number | null
  grossMarginEur: number | null
  grossMarginYear: number | null
  ebitda: number | null
  ebitdaEur: number | null
  ebitdaAvg: number | null
  ebitdaAvgEur: number | null
  ebitdaYear: number | null
  ebitdaPctRevenue: number | null
  fte: number | null
  fteYear: number | null
  revenueYoyGrowthPctMin: number | null
  revenueYoyGrowthPctMax: number | null
  revenueResults: number[]
  revenueYears: number[]
  grossMarginResults: number[]
  grossMarginYears: number[]
  ebitdaResults: number[]
  ebitdaYears: number[]
  ebitdaPctRevenueResults: number[]
  ebitdaPctRevenueYears: number[]
  fteResults: number[]
  fteYears: number[]
  ratingGrowth: number | null
  ratingGrossMargin: number | null
  ratingEbitda: number | null
  ratingConversion: number | null
  ratingOverall: number | null
  assetIds: number[]
  assetNames: string[]
  readonly assetUrls: string[]
  currency: string
  financialsUpdatedAt: string | null
  annualReportsCheckedAt: string | null
  ebitdaPctRevenueAvg: number | null
  previousNames: string[]
}

export interface LegalEntityRating {
  readonly legalEntityId: number
  growth: number | null
  grossMargin: number | null
  ebitda: number | null
  conversion: number | null
  overall: number | null
}

export interface LegalEntitySectorCode {
  readonly id: number
  legalEntityId: number
  sectorCodeId: number
  order: number
}

export interface LegalEntityShareholder {
  readonly id: number
  legalEntityId: number
  type: string
  name: string
  region: string
  shareholderExternalId: string
  confirmedOn: string | null
  percentageShareMin: number | null
  percentageShareMax: number | null
  percentageShareExact: number | null
  readonly linkedLegalEntityId: number | null
}

export interface LegalEntityTag {
  readonly id: number
  readonly legalEntityId: number
  readonly tagId: number
  readonly tag: string
}

export interface LegalEntityUrl {
  readonly id: number
  readonly legalEntityId: number
  uRL: string
  domain: string
  method: Shared.LegalEntityUrlMethod
  source: Shared.LegalEntityUrlSource
  checkedAt: string | null
  order: number
}

export interface Linkedin {
  about: string | null
  affiliated: LinkedinAffiliated[]
  company_id: string
  company_size: string | null
  country_code: string | null
  crunchbase_url: string | null
  employees: LinkedinEmployee[]
  employees_in_linkedin: number | null
  followers: number | null
  formatted_locations: string[]
  founded: number
  funding: LinkedinFunding | null
  headquarters: string | null
  id: string | null
  image: string
  industries: string | null
  investors: string[]
  locations: string[]
  logo: string
  name: string
  organization_type: string | null
  similar: LinkedinSimilar[]
  slogan: string | null
  specialties: string | null
  sphere: string | null
  stock_info: LinkedinStockInfo | null
  timestamp: string
  type: string | null
  updates: LinkedinUpdate[]
  url: string
  website: string
}

export interface LinkedinAffiliated {
  Links: string
  subtitle: string
  title: string
}

export interface LinkedinEmployee {
  img: string
  link: string
  subtitle: string
  title: string
}

export interface LinkedinFunding {
  last_round_date: string
  last_round_type: string
  rounds: number
}

export interface LinkedinSimilar {
  Links: string
  subtitle: string
  title: string
}

export interface LinkedinStockInfo {
  datetime: string | null
  id: string | null
  stock_exchange: string | null
  stock_price_change: string | null
  stock_provider: string | null
  stock_ticker: string | null
}

export interface LinkedinUpdate {
  comments_count: number | null
  external_link: string | null
  images: string[]
  text: string | null
  time: string | null
  title: string | null
}

export interface ListArgs {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListCounts {
  total: number
  filtered: number
}

export interface ListOfShareholders {
  id: number
  legalEntityId: number
  fileId: number | null
  documentDate: string | null
  checkedAt: string
}

export interface ListedSecurityBrokerRecommendation {
  readonly id: number
  listedSecurityId: number
  buyCount: number
  holdCount: number
  sellCount: number
  periodicity: BrokerRecommendationPeriodicity
  startDate: string
  endDate: string
}

export interface ListedSecurityChartData {
  sharePrice: ListedSecurityPrice[]
  brokerRecommendation: ListedSecurityBrokerRecommendation[]
}

export interface ListedSecurityList {
  items: ListedSecurityListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface ListedSecurityListItem {
  id: number
  name: string | null
  tradingCurrency: string | null
}

export interface ListedSecurityPrice {
  readonly id: number
  listedSecurityId: number
  price: number
  date: string
}

export interface ListedSecurityValuation {
  readonly id: number
  listedSecurityId: number
  sharePriceDate: string
  marketCapitalization: number
  enterpriseValue: number | null
  fromLatestValuationMetrics: boolean
  financialResultYear: number
  financialResultQuarter: number
  financialResultFiscalEndDate: string
  financialResultPeriodicity: Shared.FinancialResultPeriodicityType
  enterpriseValueRevenueRatio: number | null
  enterpriseValueEbitdaRatio: number | null
  enterpriseValueEbitdaMinusCapexRatio: number | null
  enterpriseValueEbitRatio: number | null
  enterpriseValueInvestedCapitalRatio: number | null
  enterpriseValueFreeCashFlowRatio: number | null
}

export interface ListedSecurityValuationMetrics {
  sharePrice: number | null
  targetPrice: number | null
  marketCap: number | null
  enterpriseValue: number | null
  evEbitdaLtmratio: number | null
}

export interface MethodInfo {
  name: string
  params: PropertyInfo[]
  result: string
  summary: string
  description: string
  public: boolean
}

export interface MultiPolygon {}

export interface Person {
  readonly id: number
  firstName: string
  live: boolean
  createdAt: string | null
  updatedAt: string | null
  publishedAt: string | null
  unpublishedAt: string | null
  lastName: string
  birthYear: number | null
  nationality: string | null
  locationRegion: string | null
  linkedInUrl: string | null
  linkedinPersonId: string | null
  linkedinConnectionsCount: number | null
  investorId: number | null
}

export interface PersonList {
  items: PersonListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface PersonListItem {
  readonly id: number
  readonly fullName: string
  firstName: string
  lastName: string
  birthYear: number | null
  nationality: string | null
  locationRegion: string | null
  linkedInUrl: string | null
  investorId: number | null
  readonly investorName: string | null
  regions: string[]
  sectors: string[]
  subsectors: string[]
  assetIds: number[]
  assets: PersonListItemAsset[]
}

export interface PersonListItemAsset {
  id: number
  name: string
  webUrl: string
  region: string | null
  sector: string | null
  subsector: string | null
  logoFileUrl: string
  joinYear: number | null
  joinMonth: number | null
  managementPosition: string | null
  position: string | null
}

export interface Point {}

export interface PropertyInfo {
  name: string
  type: string
  readonly: boolean
  description: string
}

export interface RPCServiceInfo {
  name: string
  summary: string
  description: string
  methods: MethodInfo[]
}

export interface RegionList {
  items: RegionListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface RegionListItem {
  id: number
  name: string
  title: string
}

export interface RelatedConferenceEdition {
  id: number
  name: string
  logoFileUrl: string | null
  startDate: string
  endDate: string
  tagNames: string[]
  tagIds: number[]
  venueCountryCode: string
  venueCity: string
  assets: RelatedConferenceEditionAsset[]
}

export interface RelatedConferenceEditionAsset {
  id: number | null
  name: string
  logoFileUrl: string | null
}

export interface RelatedDealAdvisor {
  advisorId: number
  advisorName: string
  advisorLogoFileUrl: string | null
  assets: DealAdvisorAsset[]
  assetsCount: number
  dealCount: number
  dealIds: number[]
  dealEbitdasEur: number[]
  coreFocus: Shared.AdvisoryActivity
}

export interface SearchResultItem {
  id: number
  type: SearchItemType
  name: string
  matchedTerm: string
  matchedField: string
  similarity: number
  score: number
  description: string | null
  regions: string[]
  imageId: number | null
  imageUrl: string | null
}

export interface Sector {
  id: number
  name: string
  title: string
  imageFileId: number | null
  readonly imageFileUrl: string | null
  imageLargeFileId: number | null
  readonly imageLargeFileUrl: string | null
}

export interface SectorList {
  items: Sector[]
  counts: ListCounts
  args: ListArgs
}

export interface SimilarAssetDealsList {
  items: SimilarAssetDealsListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface SimilarAssetDealsListItem {
  readonly id: number
  publicationDate: string | null
  asset: string | null
  assetLogoFileUrl: string | null
  linkedAssetId: number | null
  linkedAssetDescription: string | null
  region: string | null
  sector: string | null
  subsector: string | null
  currency: string | null
  division: string | null
  dealStatus: Shared.DealStatus | null
  dealType: Shared.DealType | null
  reasons: Shared.DealReason[]
  announcementDate: string
  announcementDateYear: number | null
  announcementDateMonth: number | null
  tags: string[]
  tagIds: number[]
  investorIds: number[]
  advisorIds: number[]
  readonly live: boolean
  highlightedBuyerId: number | null
  highlightedBuyerType: Shared.DealSideType | null
  highlightedBuyerReason: Shared.DealReason | null
  highlightedBuyerName: string | null
  highlightedBuyerDivision: string | null
  highlightedBuyerRegion: string | null
  highlightedBuyerShare: Shared.DealSideShare | null
  highlightedBuyerSharePct: number | null
  highlightedSellerId: number | null
  highlightedSellerType: Shared.DealSideType | null
  highlightedSellerReason: Shared.DealReason | null
  highlightedSellerName: string | null
  highlightedSellerDivision: string | null
  highlightedSellerRegion: string | null
  highlightedSellerShare: Shared.DealSideShare | null
  highlightedSellerSharePct: number | null
  readonly buyersInfo: DealSummarySide[]
  readonly sellersInfo: DealSummarySide[]
  buyerAdvisorIds: number[]
  sellerAdvisorIds: number[]
  buyerAssetIds: number[]
  buyerInvestorIds: number[]
  buyerReasons: Shared.DealReason[]
  buyerShares: Shared.DealSideShare[]
  buyerSharePcts: number[]
  buyerLogoFileUrls: string[]
  sellerAssetIds: number[]
  sellerInvestorIds: number[]
  sellerReasons: Shared.DealReason[]
  sellerShares: Shared.DealSideShare[]
  sellerSharePcts: number[]
  sellerLogoFileUrls: string[]
  buyers: number
  buyerAssetNames: string[]
  buyerInvestorNames: string[]
  buyerNames: string[]
  buyerTypes: Shared.DealSideType[]
  buyerLeadingParties: boolean[]
  buyerLinkedIds: number[]
  sellers: number
  sellerAssetNames: string[]
  sellerInvestorNames: string[]
  sellerNames: string[]
  sellerTypes: Shared.DealSideType[]
  sellerLeadingParties: boolean[]
  sellerLinkedIds: number[]
  fte: number | null
  fteYear: number | null
  revenue: number | null
  revenueEur: number | null
  revenueYear: number | null
  ebitda: number | null
  ebitdaEur: number | null
  ebitdaYear: number | null
  ebit: number | null
  ebitEur: number | null
  ebitYear: number | null
  totalAssets: number | null
  totalAssetsEur: number | null
  totalAssetsYear: number | null
  ev: number | null
  evEur: number | null
  evYear: number | null
  equity: number | null
  equityEur: number | null
  equityYear: number | null
  evEbitdaMultiple: number | null
  evEbitdaMultipleYear: number | null
  evEbitMultiple: number | null
  evEbitMultipleYear: number | null
  evRevenueMultiple: number | null
  evRevenueMultipleYear: number | null
  evTotalAssetsMultiple: number | null
  evTotalAssetsMultipleYear: number | null
  fundingRoundAmountRaised: number | null
  fundingRoundAmountRaisedEur: number | null
  fundingRoundAmountRaisedYear: number | null
  fundingRoundPreMoneyValuation: number | null
  fundingRoundPreMoneyValuationEur: number | null
  fundingRoundPreMoneyValuationYear: number | null
  fundingRoundPostMoneyValuation: number | null
  fundingRoundPostMoneyValuationEur: number | null
  fundingRoundPostMoneyValuationYear: number | null
  fundingRoundType: Shared.DealFundingRoundType | null
  readonly assetGainProUrl: string | null
  readonly gainProUrl: string
  readonly assetWebUrl: string | null
  readonly webUrl: string
  relevanceRank: number
}

export interface Subsector {
  id: number
  name: string
  title: string
  sector: string
  imageFileId: number | null
  readonly imageFileUrl: string | null
  imageLargeFileId: number | null
  readonly imageLargeFileUrl: string | null
}

export interface SubsectorList {
  items: Subsector[]
  counts: ListCounts
  args: ListArgs
}

export interface SuggestCityResponse {
  googlePlaceId: string
  description: string
}

export interface SuggestedTag {
  id: number
  name: string
  assetCount: number
}

export interface TagList {
  items: TagListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface TagListItem {
  id: number
  name: string
  assetCount: number
}

export interface TicketAttribute {
  type: TicketAttributeType
  value: any
}

export interface TypeInfo {
  name: string
  summary: string
  description: string
  properties: PropertyInfo[]
}

export interface Url {
  readonly id: number
  url: string
  domain: string
  isPrimary: boolean
  isIndexed: boolean
  assetId: number | null
  advisorId: number | null
  investorId: number | null
  legalEntityId: number | null
}

export interface UserAccount {
  readonly id: number
  readonly status: Shared.UserStatus
  customerId: number
  username: string
  firstName: string
  lastName: string
  email: string
  currency: string
  unitSystem: Shared.UserUnitSystem
  role: Shared.UserRole
  recommendRegions: string[]
  recommendSubsectors: string[]
  readonly invitationLink: string
  readonly invitationExpired: boolean
  readonly invitationSentAt: string
  isSsoUser: boolean
  emailSourcingUpdates: boolean
  readonly emailSourcingUpdatesLastSentAt: string | null
  emailResearchUpdates: boolean
  readonly emailResearchUpdatesLastSentAt: string | null
  emailAssetUpdatesNotification: boolean
  readonly emailAssetUpdatesNotificationLastSentAt: string | null
  readonly createdAt: string
  readonly lastAccessAt: string | null
  readonly onboardedAt: string | null
  readonly hasSharedBookmarks: boolean
  readonly lastFailedLogin: string | null
  readonly failedLoginCount: number
  readonly deactivatedAt: string | null
  readonly deactivatedReason: string
  isApiUser: boolean
}

export interface UserList {
  items: UserListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface UserListItem {
  userId: number
  status: string
  username: string
  customerId: number
  role: Shared.UserRole
  firstName: string
  lastName: string
  email: string
  lastAccessAt: string | null
}

export interface UserProfile {
  readonly id: number
  readonly username: string
  readonly email: string
  isSsoUser: boolean
  readonly role: Shared.UserRole
  firstName: string
  lastName: string
  currency: string
  unitSystem: Shared.UserUnitSystem
  readonly customerId: number
  readonly customerName: string
  readonly customerLogoUrl: string
  recommendRegions: string[]
  recommendSubsectors: string[]
  readonly intercomHmac: string
  emailSourcingUpdates: boolean
  emailResearchUpdates: boolean
  emailAssetUpdatesNotification: boolean
  sessionTrackingProjectCode: string | null
  readonly sessionTrackingProjectCodeUpdatedAt: string | null
  readonly featureExportAssetsMaxExportsPerMonth: number
  readonly featureExportAssetsMaxItemsPerExport: number
  readonly featureExportAssetsExportsThisMonth: number
  readonly featureExportDealsMaxExportsPerMonth: number
  readonly featureExportDealsMaxItemsPerExport: number
  readonly featureExportDealsExportsThisMonth: number
  readonly featureExportOwnersMaxExportsPerMonth: number
  readonly featureExportOwnersMaxItemsPerExport: number
  readonly featureExportOwnersExportsThisMonth: number
  readonly featureExportAdvisorsMaxExportsPerMonth: number
  readonly featureExportAdvisorsMaxItemsPerExport: number
  readonly featureExportAdvisorsExportsThisMonth: number
  readonly featureExportFinancialsMaxPerMonth: number
  readonly featureExportFinancialsThisMonth: number
  readonly featureExportFactsheetMaxPerMonth: number
  readonly featureExportFactsheetThisMonth: number
  readonly featureExportAnnualReportMaxPerMonth: number
  readonly featureExportAnnualReportThisMonth: number
  readonly featureExportIndustryMaxPerMonth: number
  readonly featureExportIndustryThisMonth: number
  readonly featureExportFinancials: boolean
  readonly featureSessionTracking: boolean
  readonly featureSessionTrackingTimeout: number
  readonly featureDealCloud: boolean
}

export interface UserRecentVisitDetails {
  id: number
  userId: number
  lastViewedAt: string
  objectId: number
  objectType: ObjectType
  name: string
  description: string | null
  regions: string[]
  imageId: number | null
  imageUrl: string | null
}

export interface UserRecentVisitList {
  items: UserRecentVisitDetails[]
}

export interface UserSessionList {
  items: UserSessionListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface UserSessionListItem {
  userId: number
  firstName: string
  lastName: string
  email: string
  sessionProjectCode: string
  sessionStartedAt: string
  sessionLastActivityAt: string
  sessionApiCalls: number
  sessionDuration: number
}

export interface Value {
  name: string
  desc: string
  value: any
}

export interface ZendeskLogin {
  jwt: string
}

export interface BeamerTranslation {
  title: string
  content: string
  contentHtml: string
  language: string
  category: string
  linkUrl: string
  linkText: string
  images: string[]
  postUrl: string
}

export interface ActivateAccountParams {
  token: string
  password: string
}

export interface CreateTicketParams {
  type: TicketType
  body: string
  attributes: TicketAttribute[]
}

export interface DeleteRecentVisitParams {
  id: number
}

export interface ForgotPasswordParams {
  username: string
}

export interface GetAuthenticationTypeParams {
  email: string
  redirectUrl: string | null
}

export interface LoginParams {
  username: string
  password: string
}

export interface LoginSsoParams {
  workOsAccessToken: string
}

export interface ResetPasswordParams {
  token: string
  newPassword: string
}

export interface TrackVisitParams {
  objectId: number
  objectType: ObjectType
}

export interface UnsubscribeEmailParams {
  token: string
  unsubscribeEmailSourcingUpdates: boolean
  unsubscribeEmailResearchUpdates: boolean
  unsubscribeEmailAssetUpdatesNotification: boolean
}

export interface UpdatePasswordParams {
  oldPassword: string
  newPassword: string
}

export interface UpdateUserProfileParams {
  id: number
  partial: object
}

export interface CreateUserParams {
  partial: object
}

export interface DeactivateUserParams {
  userId: number
}

export interface DeleteUserParams {
  id: number
}

export interface ExportUserSessionsParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ExportUsersParams {
  columns: string[]
  filename: string
  filter: Filter[]
  search: string
  sort: string[]
}

export interface GetUserParams {
  id: number
}

export interface GetUserInvitationLinkParams {
  userId: number
}

export interface ListUserSessionsParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListUsersParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ReactivateUserParams {
  userId: number
}

export interface ResendInvitationParams {
  userId: number
}

export interface UpdateUserParams {
  id: number
  partial: object
}

export interface AddToDealCloudParams {
  assetIds: number[]
}

export interface DownloadAllShareholderFilesParams {
  legalEntityId: number
  filename: string
}

export interface DownloadAssetAnnualReportFilesParams {
  assetId: number
  filename: string
}

export interface DownloadAssetFilesParams {
  assetId: number
  filename: string
}

export interface DownloadLegalEntityAnnualReportFilesParams {
  legalEntityId: number
  filename: string
}

export interface ExportAdvisorDealsParams {
  filter: Filter[]
  sort: string[]
  columns: string[]
  search: string
  filename: string
}

export interface ExportAdvisorsParams {
  filter: Filter[]
  sort: string[]
  columns: string[]
  search: string
  filename: string
}

export interface ExportAnnualReportFileParams {
  id: number
}

export interface ExportAssetDealsParams {
  assetId: number
  filename: string
}

export interface ExportAssetFinancialsParams {
  id: number
}

export interface ExportAssetsParams {
  columns: string[]
  filename: string
  filter: Filter[]
  search: string
  sort: string[]
}

export interface ExportConferenceEditionExhibitorsParams {
  conferenceEditionId: number
  filename: string
}

export interface ExportDealsParams {
  filter: Filter[]
  sort: string[]
  columns: string[]
  filename: string
}

export interface ExportFactsheetParams {
  id: number
  preview: boolean
}

export interface ExportIndustryProfileParams {
  id: number
  preview: boolean
}

export interface ExportInvestorsParams {
  columns: string[]
  filename: string
  filter: Filter[]
  search: string
  sort: string[]
}

export interface ExportLegalEntityFinancialsParams {
  id: number
}

export interface ExportShareholderFileParams {
  id: number
}

export interface GetActiveConsolidatorsParams {
  source: AssetSourceType
  id: number
}

export interface GetActiveInvestorsParams {
  source: AssetSourceType
  id: number
}

export interface GetActiveStrategicAcquirersParams {
  source: AssetSourceType
  id: number
}

export interface GetAdvisorParams {
  id: number
  preview: boolean
}

export interface GetAdvisorAssetClientsParams {
  id: number
  regions: string[]
}

export interface GetAdvisorInvestorClientsParams {
  id: number
  regions: string[]
}

export interface GetArticleParams {
  id: number
  preview: boolean
}

export interface GetAssetParams {
  assetId: number
  preview: boolean
}

export interface GetAssetAdvisorsParams {
  id: number
}

export interface GetAssetConferenceEditionsParams {
  id: number
}

export interface GetAssetLinkedinDataParams {
  id: number
}

export interface GetAssetOwnershipDistributionParams {
  source: AssetSourceType
  id: number
}

export interface GetAssetRelevantIndustriesParams {
  assetId: number
}

export interface GetCityByPlaceIdParams {
  googlePlaceId: string
}

export interface GetCompetitorParams {
  id: number
}

export interface GetDealParams {
  id: number
}

export interface GetEmailAddressParams {
  personId: number
  objectId: number
  objectType: ObjectType
}

export interface GetGeometryForPlaceIdParams {
  placeId: string
}

export interface GetGlobalUltimateOwnerParams {
  globalUltimateOwnerId: number
}

export interface GetIndustryParams {
  id: number
  preview: boolean
}

export interface GetIndustryMarketSegmentByIdParams {
  id: number
}

export interface GetIndustrySegmentDealsByYearParams {
  industryId: number
  fromYear: number
}

export interface GetInvestorParams {
  id: number
  preview: boolean
}

export interface GetInvestorDealsPerTypeByYearParams {
  investorId: number
  maxYears: number
}

export interface GetInvestorProfileStrategiesParams {
  investorId: number
}

export interface GetInvestorStrategyParams {
  id: number
}

export interface GetLegalEntityParams {
  id: number
}

export interface GetListedSecurityChartDataParams {
  listedSecurityId: number
  startDate: string
}

export interface GetListedSecurityValuationParams {
  listedSecurityId: number
}

export interface GetListedSecurityValuationsParams {
  listedSecurityId: number
}

export interface GetLngLatByPlaceIdParams {
  googlePlaceId: string
}

export interface GetPersonParams {
  id: number
}

export interface GetRelatedAssetsParams {
  source: ListRelatedAssetsSource
  id: number
  limit: number
  regions: string[]
}

export interface GetRelatedConferenceEditionsParams {
  source: AssetSourceType
  id: number
  startDate: string | null
  endDate: string | null
  venueCountryCode: string[]
}

export interface GetRelatedDealAdvisorsParams {
  source: AssetSourceType
  id: number
}

export interface GetSectorParams {
  name: string
}

export interface GetSimilarAssetsParams {
  likeAssetId: number
  limit: number
}

export interface GetSubsectorParams {
  name: string
}

export interface ListAdvisorDealsParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListAdvisorsParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListArticlesParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListAssetsParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListAssociatedTagsParams {
  tagIds: number[]
  limit: number
  assets: boolean
}

export interface ListCompetitorsParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListConferenceEditionsParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListCurrenciesParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListDealsParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListIndustriesParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListIndustryMarketSegmentsParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListInvestorFundsParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListInvestorStrategiesParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListInvestorsParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListLegalEntitiesParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListListedSecuritiesParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListPersonsParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListRegionsParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListSectorsParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListSimilarAssetDealsParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
  similarToIds: number[]
}

export interface ListSubsectorsParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListTagsParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface SearchParams {
  query: string
  limit: number
  assets: boolean
  industries: boolean
  entities: boolean
  investors: boolean
  advisors: boolean
  conferenceEditions: boolean
}

export interface SearchDomainParams {
  url: string
}

export interface SuggestCityParams {
  input: string
  sessionToken: string
}

export interface SuggestLocalityParams {
  input: string
  limit: number
}

export interface SuggestTagsParams {
  text: string
  limit: number
  assets: boolean
}

export interface TrackActivityParams {
  actions: string[]
}

export interface CreateCustomAssetListParams {
  partial: object
}

export interface CreateCustomAssetQueryParams {
  partial: object
}

export interface CustomAssetListAddAssetsParams {
  id: number
  assetIds: number[]
}

export interface CustomAssetListImportFromUrlsParams {
  customAssetListId: number
  urls: string[]
}

export interface CustomAssetListRemoveAssetsParams {
  id: number
  assetIds: number[]
}

export interface DeleteCustomAssetListParams {
  id: number
}

export interface DeleteCustomAssetQueryParams {
  id: number
}

export interface GetCustomAssetListParams {
  id: number
}

export interface GetCustomAssetListUrlImportTaskStateParams {
  id: number
}

export interface GetCustomAssetQueryParams {
  id: number
}

export interface GetDealsPerTypeByYearParams {
  type: CustomAssetListEnum
  id: number
  maxYears: number
}

export interface GetListRatingsParams {
  id: number
  type: CustomAssetListEnum
}

export interface LeaveCustomAssetListParams {
  id: number
}

export interface LeaveCustomAssetQueryParams {
  id: number
}

export interface ListCustomAssetQueriesParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListUpdatesParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
  id: number
  type: CustomAssetListEnum
}

export interface SetCustomAssetListLastViewedAtParams {
  id: number
  lastViewedAt: string
}

export interface SetCustomAssetQueryLastViewedAtParams {
  id: number
  lastViewedAt: string
}

export interface ShareCustomAssetListParams {
  id: number
  userIds: number[]
}

export interface ShareCustomAssetQueryParams {
  id: number
  userIds: number[]
}

export interface TransferCustomAssetListParams {
  id: number
  newOwnerUserId: number
}

export interface TransferCustomAssetQueryParams {
  id: number
  newOwnerUserId: number
}

export interface UnshareCustomAssetListParams {
  id: number
  userIds: number[]
}

export interface UnshareCustomAssetQueryParams {
  id: number
  userIds: number[]
}

export interface UpdateCustomAssetListParams {
  id: number
  partial: object
}

export interface UpdateCustomAssetQueryParams {
  id: number
  partial: object
}

export interface ErrorParams {
  code: number
  message: string
  data: any
}

export const account = {
  /**
   * Result: string
   */
  activateAccount: (params: ActivateAccountParams) =>
    Jsonrpc.createMethod('account.activateAccount', params),
  createTicket: (params: CreateTicketParams) =>
    Jsonrpc.createMethod('account.createTicket', params),
  deleteRecentVisit: (params: DeleteRecentVisitParams) =>
    Jsonrpc.createMethod('account.deleteRecentVisit', params),
  forgotPassword: (params: ForgotPasswordParams) =>
    Jsonrpc.createMethod('account.forgotPassword', params),
  /**
   * Result: GetAuthenticationTypeResponse
   */
  getAuthenticationType: (params: GetAuthenticationTypeParams) =>
    Jsonrpc.createMethod('account.getAuthenticationType', params),
  /**
   * Result: UserProfile
   */
  getUserProfile: () => Jsonrpc.createMethod('account.getUserProfile'),
  /**
   * Result: UserRecentVisitList
   */
  listRecentVisits: () => Jsonrpc.createMethod('account.listRecentVisits'),
  /**
   * Result: string
   */
  login: (params: LoginParams) => Jsonrpc.createMethod('account.login', params),
  /**
   * Result: string
   */
  loginSso: (params: LoginSsoParams) => Jsonrpc.createMethod('account.loginSso', params),
  /**
   * Result: ZendeskLogin
   */
  loginZendesk: () => Jsonrpc.createMethod('account.loginZendesk'),
  logout: () => Jsonrpc.createMethod('account.logout'),
  resetPassword: (params: ResetPasswordParams) =>
    Jsonrpc.createMethod('account.resetPassword', params),
  trackVisit: (params: TrackVisitParams) => Jsonrpc.createMethod('account.trackVisit', params),
  unsubscribeEmail: (params: UnsubscribeEmailParams) =>
    Jsonrpc.createMethod('account.unsubscribeEmail', params),
  updatePassword: (params: UpdatePasswordParams) =>
    Jsonrpc.createMethod('account.updatePassword', params),
  updateUserProfile: (params: UpdateUserProfileParams) =>
    Jsonrpc.createMethod('account.updateUserProfile', params),
}

export const admin = {
  /**
   * Result: UserAccount
   */
  createUser: (params: CreateUserParams) => Jsonrpc.createMethod('admin.createUser', params),
  deactivateUser: (params: DeactivateUserParams) =>
    Jsonrpc.createMethod('admin.deactivateUser', params),
  deleteUser: (params: DeleteUserParams) => Jsonrpc.createMethod('admin.deleteUser', params),
  /**
   * Result: string
   */
  exportUserSessions: (params: ExportUserSessionsParams) =>
    Jsonrpc.createMethod('admin.exportUserSessions', params),
  /**
   * Result: string
   */
  exportUsers: (params: ExportUsersParams) => Jsonrpc.createMethod('admin.exportUsers', params),
  /**
   * Result: UserAccount
   */
  getUser: (params: GetUserParams) => Jsonrpc.createMethod('admin.getUser', params),
  /**
   * Result: string
   */
  getUserInvitationLink: (params: GetUserInvitationLinkParams) =>
    Jsonrpc.createMethod('admin.getUserInvitationLink', params),
  /**
   * Result: UserSessionList
   */
  listUserSessions: (params: ListUserSessionsParams) =>
    Jsonrpc.createMethod('admin.listUserSessions', params),
  /**
   * Result: UserList
   */
  listUsers: (params: ListUsersParams) => Jsonrpc.createMethod('admin.listUsers', params),
  reactivateUser: (params: ReactivateUserParams) =>
    Jsonrpc.createMethod('admin.reactivateUser', params),
  resendInvitation: (params: ResendInvitationParams) =>
    Jsonrpc.createMethod('admin.resendInvitation', params),
  updateUser: (params: UpdateUserParams) => Jsonrpc.createMethod('admin.updateUser', params),
}

/** The data service provides methods to access and search the Gain.pro data graph. */
export const data = {
  /**
   * Result: array(DealCloudItem)
   */
  addToDealCloud: (params: AddToDealCloudParams) =>
    Jsonrpc.createMethod('data.addToDealCloud', params),
  /**
   * Result: string
   */
  downloadAllShareholderFiles: (params: DownloadAllShareholderFilesParams) =>
    Jsonrpc.createMethod('data.downloadAllShareholderFiles', params),
  /**
   * Result: string
   */
  downloadAssetAnnualReportFiles: (params: DownloadAssetAnnualReportFilesParams) =>
    Jsonrpc.createMethod('data.downloadAssetAnnualReportFiles', params),
  /**
   * Result: string
   */
  downloadAssetFiles: (params: DownloadAssetFilesParams) =>
    Jsonrpc.createMethod('data.downloadAssetFiles', params),
  /**
   * Result: string
   */
  downloadLegalEntityAnnualReportFiles: (params: DownloadLegalEntityAnnualReportFilesParams) =>
    Jsonrpc.createMethod('data.downloadLegalEntityAnnualReportFiles', params),
  /**
   * Result: string
   */
  exportAdvisorDeals: (params: ExportAdvisorDealsParams) =>
    Jsonrpc.createMethod('data.exportAdvisorDeals', params),
  /**
   * Result: string
   */
  exportAdvisors: (params: ExportAdvisorsParams) =>
    Jsonrpc.createMethod('data.exportAdvisors', params),
  /**
   * Result: AnnualReportDownloadURLs
   */
  exportAnnualReportFile: (params: ExportAnnualReportFileParams) =>
    Jsonrpc.createMethod('data.exportAnnualReportFile', params),
  /**
   * Result: string
   */
  exportAssetDeals: (params: ExportAssetDealsParams) =>
    Jsonrpc.createMethod('data.exportAssetDeals', params),
  /**
   * Result: string
   */
  exportAssetFinancials: (params: ExportAssetFinancialsParams) =>
    Jsonrpc.createMethod('data.exportAssetFinancials', params),
  /**
   * Result: string
   */
  exportAssets: (params: ExportAssetsParams) => Jsonrpc.createMethod('data.exportAssets', params),
  /**
   * Result: string
   */
  exportConferenceEditionExhibitors: (params: ExportConferenceEditionExhibitorsParams) =>
    Jsonrpc.createMethod('data.exportConferenceEditionExhibitors', params),
  /**
   * Result: string
   */
  exportDeals: (params: ExportDealsParams) => Jsonrpc.createMethod('data.exportDeals', params),
  /**
   * Result: string
   */
  exportFactsheet: (params: ExportFactsheetParams) =>
    Jsonrpc.createMethod('data.exportFactsheet', params),
  /**
   * Result: string
   */
  exportIndustryProfile: (params: ExportIndustryProfileParams) =>
    Jsonrpc.createMethod('data.exportIndustryProfile', params),
  /**
   * Result: string
   */
  exportInvestors: (params: ExportInvestorsParams) =>
    Jsonrpc.createMethod('data.exportInvestors', params),
  /**
   * Result: string
   */
  exportLegalEntityFinancials: (params: ExportLegalEntityFinancialsParams) =>
    Jsonrpc.createMethod('data.exportLegalEntityFinancials', params),
  /**
   * Result: string
   */
  exportShareholderFile: (params: ExportShareholderFileParams) =>
    Jsonrpc.createMethod('data.exportShareholderFile', params),
  /**
   * Result: array(ActiveConsolidator)
   */
  getActiveConsolidators: (params: GetActiveConsolidatorsParams) =>
    Jsonrpc.createMethod('data.getActiveConsolidators', params),
  /**
   * Result: array(ActiveInvestor)
   */
  getActiveInvestors: (params: GetActiveInvestorsParams) =>
    Jsonrpc.createMethod('data.getActiveInvestors', params),
  /**
   * Result: array(ActiveStrategicAcquirer)
   */
  getActiveStrategicAcquirers: (params: GetActiveStrategicAcquirersParams) =>
    Jsonrpc.createMethod('data.getActiveStrategicAcquirers', params),
  /**
   * Result: Advisor
   */
  getAdvisor: (params: GetAdvisorParams) => Jsonrpc.createMethod('data.getAdvisor', params),
  /**
   * Result: array(AdvisorAssetClient)
   */
  getAdvisorAssetClients: (params: GetAdvisorAssetClientsParams) =>
    Jsonrpc.createMethod('data.getAdvisorAssetClients', params),
  /**
   * Result: array(AdvisorInvestorClient)
   */
  getAdvisorInvestorClients: (params: GetAdvisorInvestorClientsParams) =>
    Jsonrpc.createMethod('data.getAdvisorInvestorClients', params),
  /**
   * Result: Article
   */
  getArticle: (params: GetArticleParams) => Jsonrpc.createMethod('data.getArticle', params),
  /**
   * Companies are referred to as 'Assets' in the API.,* Result: Asset
   */
  getAsset: (params: GetAssetParams) => Jsonrpc.createMethod('data.getAsset', params),
  /**
   * Result: array(AssetAdvisor)
   */
  getAssetAdvisors: (params: GetAssetAdvisorsParams) =>
    Jsonrpc.createMethod('data.getAssetAdvisors', params),
  /**
   * Result: array(ConferenceEditionListItem)
   */
  getAssetConferenceEditions: (params: GetAssetConferenceEditionsParams) =>
    Jsonrpc.createMethod('data.getAssetConferenceEditions', params),
  /**
   * Result: Linkedin
   */
  getAssetLinkedinData: (params: GetAssetLinkedinDataParams) =>
    Jsonrpc.createMethod('data.getAssetLinkedinData', params),
  /**
   * Result: array(AssetOwnershipCount)
   */
  getAssetOwnershipDistribution: (params: GetAssetOwnershipDistributionParams) =>
    Jsonrpc.createMethod('data.getAssetOwnershipDistribution', params),
  /**
   * Result: IndustryList
   */
  getAssetRelevantIndustries: (params: GetAssetRelevantIndustriesParams) =>
    Jsonrpc.createMethod('data.getAssetRelevantIndustries', params),
  /**
   * Result: GetCityByPlaceIdResponse
   */
  getCityByPlaceId: (params: GetCityByPlaceIdParams) =>
    Jsonrpc.createMethod('data.getCityByPlaceId', params),
  /**
   * Deprecated. Will no longer be supported as of 01/02/2025.,* Result: Competitor
   */
  getCompetitor: (params: GetCompetitorParams) =>
    Jsonrpc.createMethod('data.getCompetitor', params),
  /**
   * Result: Deal
   */
  getDeal: (params: GetDealParams) => Jsonrpc.createMethod('data.getDeal', params),
  /**
   * Result: GetEmailAddressResult
   */
  getEmailAddress: (params: GetEmailAddressParams) =>
    Jsonrpc.createMethod('data.getEmailAddress', params),
  /**
   * Result: GeoPolygon
   */
  getGeometryForPlaceId: (params: GetGeometryForPlaceIdParams) =>
    Jsonrpc.createMethod('data.getGeometryForPlaceId', params),
  /**
   * Result: GlobalUltimateOwner
   */
  getGlobalUltimateOwner: (params: GetGlobalUltimateOwnerParams) =>
    Jsonrpc.createMethod('data.getGlobalUltimateOwner', params),
  /**
   * Result: Industry
   */
  getIndustry: (params: GetIndustryParams) => Jsonrpc.createMethod('data.getIndustry', params),
  /**
   * Result: IndustryMarketSegmentListItem
   */
  getIndustryMarketSegmentById: (params: GetIndustryMarketSegmentByIdParams) =>
    Jsonrpc.createMethod('data.getIndustryMarketSegmentById', params),
  /**
   * Result: array(IndustrySegmentDealByYear)
   */
  getIndustrySegmentDealsByYear: (params: GetIndustrySegmentDealsByYearParams) =>
    Jsonrpc.createMethod('data.getIndustrySegmentDealsByYear', params),
  /**
   * Result: Investor
   */
  getInvestor: (params: GetInvestorParams) => Jsonrpc.createMethod('data.getInvestor', params),
  /**
   * Result: array(InvestorDealsPerTypeByYear)
   */
  getInvestorDealsPerTypeByYear: (params: GetInvestorDealsPerTypeByYearParams) =>
    Jsonrpc.createMethod('data.getInvestorDealsPerTypeByYear', params),
  /**
   * Result: array(InvestorProfileStrategy)
   */
  getInvestorProfileStrategies: (params: GetInvestorProfileStrategiesParams) =>
    Jsonrpc.createMethod('data.getInvestorProfileStrategies', params),
  /**
   * Result: InvestorStrategy
   */
  getInvestorStrategy: (params: GetInvestorStrategyParams) =>
    Jsonrpc.createMethod('data.getInvestorStrategy', params),
  /**
   * Result: BeamerPost
   */
  getLatestFeature: () => Jsonrpc.createMethod('data.getLatestFeature'),
  /**
   * Result: LegalEntity
   */
  getLegalEntity: (params: GetLegalEntityParams) =>
    Jsonrpc.createMethod('data.getLegalEntity', params),
  /**
   * Result: ListedSecurityChartData
   */
  getListedSecurityChartData: (params: GetListedSecurityChartDataParams) =>
    Jsonrpc.createMethod('data.getListedSecurityChartData', params),
  /**
   * Result: ListedSecurityValuationMetrics
   */
  getListedSecurityValuation: (params: GetListedSecurityValuationParams) =>
    Jsonrpc.createMethod('data.getListedSecurityValuation', params),
  /**
   * Result: array(ListedSecurityValuation)
   */
  getListedSecurityValuations: (params: GetListedSecurityValuationsParams) =>
    Jsonrpc.createMethod('data.getListedSecurityValuations', params),
  /**
   * Result: GetLonLatByPlaceIdResponse
   */
  getLngLatByPlaceId: (params: GetLngLatByPlaceIdParams) =>
    Jsonrpc.createMethod('data.getLngLatByPlaceId', params),
  /**
   * Result: Person
   */
  getPerson: (params: GetPersonParams) => Jsonrpc.createMethod('data.getPerson', params),
  /**
   * Result: AssetList
   */
  getRelatedAssets: (params: GetRelatedAssetsParams) =>
    Jsonrpc.createMethod('data.getRelatedAssets', params),
  /**
   * Result: array(RelatedConferenceEdition)
   */
  getRelatedConferenceEditions: (params: GetRelatedConferenceEditionsParams) =>
    Jsonrpc.createMethod('data.getRelatedConferenceEditions', params),
  /**
   * Result: array(RelatedDealAdvisor)
   */
  getRelatedDealAdvisors: (params: GetRelatedDealAdvisorsParams) =>
    Jsonrpc.createMethod('data.getRelatedDealAdvisors', params),
  /**
   * Result: Sector
   */
  getSector: (params: GetSectorParams) => Jsonrpc.createMethod('data.getSector', params),
  /**
   * Result: AssetList
   */
  getSimilarAssets: (params: GetSimilarAssetsParams) =>
    Jsonrpc.createMethod('data.getSimilarAssets', params),
  /**
   * Result: Subsector
   */
  getSubsector: (params: GetSubsectorParams) => Jsonrpc.createMethod('data.getSubsector', params),
  /**
   * Result: AdvisorDealsList
   */
  listAdvisorDeals: (params: ListAdvisorDealsParams) =>
    Jsonrpc.createMethod('data.listAdvisorDeals', params),
  /**
   * Result: AdvisorList
   */
  listAdvisors: (params: ListAdvisorsParams) => Jsonrpc.createMethod('data.listAdvisors', params),
  /**
   * Result: ArticleList
   */
  listArticles: (params: ListArticlesParams) => Jsonrpc.createMethod('data.listArticles', params),
  /**
    * Search companies based on all available information. The data.AssetListItem type
is a denormalized version of data.Asset, with one-to-many relationships reduced to arrays and financials
available in both original currencies and standardized Euro values for easier filtering.,* Result: AssetList
    */
  listAssets: (params: ListAssetsParams) => Jsonrpc.createMethod('data.listAssets', params),
  /**
   * Result: array(AssociatedTag)
   */
  listAssociatedTags: (params: ListAssociatedTagsParams) =>
    Jsonrpc.createMethod('data.listAssociatedTags', params),
  /**
    * Deprecated. Will no longer be supported after 01/02/2025. Information on an asset's
competitors will be provided via the 'listAsset' method.,* Result: CompetitorList
    */
  listCompetitors: (params: ListCompetitorsParams) =>
    Jsonrpc.createMethod('data.listCompetitors', params),
  /**
   * Result: ConferenceEditionList
   */
  listConferenceEditions: (params: ListConferenceEditionsParams) =>
    Jsonrpc.createMethod('data.listConferenceEditions', params),
  /**
   * Result: CurrencyList
   */
  listCurrencies: (params: ListCurrenciesParams) =>
    Jsonrpc.createMethod('data.listCurrencies', params),
  /**
    * Search deals based on all available deal information. The data.DealListItem type
is a denormalized version of data.Deal, with one-to-many relationships reduced to arrays and financials
available in both original currencies and standardized Euro values for easier filtering. Note that all
'Buyer' and 'Seller' fields are analogously defined.,* Result: DealList
    */
  listDeals: (params: ListDealsParams) => Jsonrpc.createMethod('data.listDeals', params),
  /**
   * Result: IndustryList
   */
  listIndustries: (params: ListIndustriesParams) =>
    Jsonrpc.createMethod('data.listIndustries', params),
  /**
   * Result: IndustryMarketSegmentList
   */
  listIndustryMarketSegments: (params: ListIndustryMarketSegmentsParams) =>
    Jsonrpc.createMethod('data.listIndustryMarketSegments', params),
  /**
   * Result: InvestorFundList
   */
  listInvestorFunds: (params: ListInvestorFundsParams) =>
    Jsonrpc.createMethod('data.listInvestorFunds', params),
  /**
    * The underlying assets that build up the values in the strategy list item fields
(such as counts and assets EBITDA values, regions and sectors) can be filtered as well. To do this use the
field names as you would in the listAssets method, but prefixed with 'assets.' (any non-prefixed filters will
filter on the strategy list item fields directly, as normal). For example, to filter the assets on EBITDA > 10
and then show only the strategies that have at least one asset for this filter, use 'assetsFiltered > 1' and
'asset.ebitda > 10'.,* Result: InvestorStrategyList
    */
  listInvestorStrategies: (params: ListInvestorStrategiesParams) =>
    Jsonrpc.createMethod('data.listInvestorStrategies', params),
  /**
   * Result: InvestorList
   */
  listInvestors: (params: ListInvestorsParams) =>
    Jsonrpc.createMethod('data.listInvestors', params),
  /**
   * Result: LegalEntityList
   */
  listLegalEntities: (params: ListLegalEntitiesParams) =>
    Jsonrpc.createMethod('data.listLegalEntities', params),
  /**
   * Result: ListedSecurityList
   */
  listListedSecurities: (params: ListListedSecuritiesParams) =>
    Jsonrpc.createMethod('data.listListedSecurities', params),
  /**
   * Result: PersonList
   */
  listPersons: (params: ListPersonsParams) => Jsonrpc.createMethod('data.listPersons', params),
  /**
   * Result: RegionList
   */
  listRegions: (params: ListRegionsParams) => Jsonrpc.createMethod('data.listRegions', params),
  /**
   * Result: SectorList
   */
  listSectors: (params: ListSectorsParams) => Jsonrpc.createMethod('data.listSectors', params),
  /**
   * Result: SimilarAssetDealsList
   */
  listSimilarAssetDeals: (params: ListSimilarAssetDealsParams) =>
    Jsonrpc.createMethod('data.listSimilarAssetDeals', params),
  /**
   * Result: SubsectorList
   */
  listSubsectors: (params: ListSubsectorsParams) =>
    Jsonrpc.createMethod('data.listSubsectors', params),
  /**
   * Result: TagList
   */
  listTags: (params: ListTagsParams) => Jsonrpc.createMethod('data.listTags', params),
  /**
   * Result: array(SearchResultItem)
   */
  search: (params: SearchParams) => Jsonrpc.createMethod('data.search', params),
  /**
   * Result: array(SearchResultItem)
   */
  searchDomain: (params: SearchDomainParams) => Jsonrpc.createMethod('data.searchDomain', params),
  /**
   * Result: array(SuggestCityResponse)
   */
  suggestCity: (params: SuggestCityParams) => Jsonrpc.createMethod('data.suggestCity', params),
  /**
   * Result: array(GeoPolygon)
   */
  suggestLocality: (params: SuggestLocalityParams) =>
    Jsonrpc.createMethod('data.suggestLocality', params),
  /**
   * Result: array(SuggestedTag)
   */
  suggestTags: (params: SuggestTagsParams) => Jsonrpc.createMethod('data.suggestTags', params),
  trackActivity: (params: TrackActivityParams) =>
    Jsonrpc.createMethod('data.trackActivity', params),
}

export const lists = {
  /**
   * Result: CustomAssetList
   */
  createCustomAssetList: (params: CreateCustomAssetListParams) =>
    Jsonrpc.createMethod('lists.createCustomAssetList', params),
  /**
   * Result: CustomAssetQueryListItem
   */
  createCustomAssetQuery: (params: CreateCustomAssetQueryParams) =>
    Jsonrpc.createMethod('lists.createCustomAssetQuery', params),
  /**
   * Result: CustomAssetList
   */
  customAssetListAddAssets: (params: CustomAssetListAddAssetsParams) =>
    Jsonrpc.createMethod('lists.customAssetListAddAssets', params),
  /**
   * Result: number(int)
   */
  customAssetListImportFromUrls: (params: CustomAssetListImportFromUrlsParams) =>
    Jsonrpc.createMethod('lists.customAssetListImportFromUrls', params),
  /**
   * Result: CustomAssetList
   */
  customAssetListRemoveAssets: (params: CustomAssetListRemoveAssetsParams) =>
    Jsonrpc.createMethod('lists.customAssetListRemoveAssets', params),
  deleteCustomAssetList: (params: DeleteCustomAssetListParams) =>
    Jsonrpc.createMethod('lists.deleteCustomAssetList', params),
  deleteCustomAssetQuery: (params: DeleteCustomAssetQueryParams) =>
    Jsonrpc.createMethod('lists.deleteCustomAssetQuery', params),
  /**
   * Result: CustomAssetList
   */
  getCustomAssetList: (params: GetCustomAssetListParams) =>
    Jsonrpc.createMethod('lists.getCustomAssetList', params),
  /**
   * Result: GetGetCustomAssetListUrlImportTaskStateResult
   */
  getCustomAssetListUrlImportTaskState: (params: GetCustomAssetListUrlImportTaskStateParams) =>
    Jsonrpc.createMethod('lists.getCustomAssetListUrlImportTaskState', params),
  /**
   * Result: array(CustomAssetList)
   */
  getCustomAssetLists: () => Jsonrpc.createMethod('lists.getCustomAssetLists'),
  /**
   * Result: CustomAssetQueryListItem
   */
  getCustomAssetQuery: (params: GetCustomAssetQueryParams) =>
    Jsonrpc.createMethod('lists.getCustomAssetQuery', params),
  /**
   * Result: array(DealsPerTypeByYear)
   */
  getDealsPerTypeByYear: (params: GetDealsPerTypeByYearParams) =>
    Jsonrpc.createMethod('lists.getDealsPerTypeByYear', params),
  /**
   * Result: CustomAssetRatings
   */
  getListRatings: (params: GetListRatingsParams) =>
    Jsonrpc.createMethod('lists.getListRatings', params),
  leaveCustomAssetList: (params: LeaveCustomAssetListParams) =>
    Jsonrpc.createMethod('lists.leaveCustomAssetList', params),
  leaveCustomAssetQuery: (params: LeaveCustomAssetQueryParams) =>
    Jsonrpc.createMethod('lists.leaveCustomAssetQuery', params),
  /**
   * Result: CustomAssetQueryList
   */
  listCustomAssetQueries: (params: ListCustomAssetQueriesParams) =>
    Jsonrpc.createMethod('lists.listCustomAssetQueries', params),
  /**
   * Result: AssetList
   */
  listUpdates: (params: ListUpdatesParams) => Jsonrpc.createMethod('lists.listUpdates', params),
  /**
   * Result: CustomAssetList
   */
  setCustomAssetListLastViewedAt: (params: SetCustomAssetListLastViewedAtParams) =>
    Jsonrpc.createMethod('lists.setCustomAssetListLastViewedAt', params),
  setCustomAssetQueryLastViewedAt: (params: SetCustomAssetQueryLastViewedAtParams) =>
    Jsonrpc.createMethod('lists.setCustomAssetQueryLastViewedAt', params),
  shareCustomAssetList: (params: ShareCustomAssetListParams) =>
    Jsonrpc.createMethod('lists.shareCustomAssetList', params),
  shareCustomAssetQuery: (params: ShareCustomAssetQueryParams) =>
    Jsonrpc.createMethod('lists.shareCustomAssetQuery', params),
  transferCustomAssetList: (params: TransferCustomAssetListParams) =>
    Jsonrpc.createMethod('lists.transferCustomAssetList', params),
  transferCustomAssetQuery: (params: TransferCustomAssetQueryParams) =>
    Jsonrpc.createMethod('lists.transferCustomAssetQuery', params),
  unshareCustomAssetList: (params: UnshareCustomAssetListParams) =>
    Jsonrpc.createMethod('lists.unshareCustomAssetList', params),
  unshareCustomAssetQuery: (params: UnshareCustomAssetQueryParams) =>
    Jsonrpc.createMethod('lists.unshareCustomAssetQuery', params),
  /**
   * Result: CustomAssetList
   */
  updateCustomAssetList: (params: UpdateCustomAssetListParams) =>
    Jsonrpc.createMethod('lists.updateCustomAssetList', params),
  /**
   * Result: CustomAssetQueryListItem
   */
  updateCustomAssetQuery: (params: UpdateCustomAssetQueryParams) =>
    Jsonrpc.createMethod('lists.updateCustomAssetQuery', params),
}

export const rpc = {
  /**
   * Result: array(EnumInfo)
   */
  enums: () => Jsonrpc.createMethod('rpc.enums'),
  error: (params: ErrorParams) => Jsonrpc.createMethod('rpc.error', params),
  /**
   * Result: array(ErrorInfo)
   */
  errorCodes: () => Jsonrpc.createMethod('rpc.errorCodes'),
  /**
   * Result: string
   */
  ping: () => Jsonrpc.createMethod('rpc.ping'),
  /**
   * Result: array(RPCServiceInfo)
   */
  services: () => Jsonrpc.createMethod('rpc.services'),
  /**
   * Result: array(TypeInfo)
   */
  types: () => Jsonrpc.createMethod('rpc.types'),
}

export type OriginalRpcMethodMap = {
  'account.activateAccount': {
    params: ActivateAccountParams
    result: string
  }
  'account.createTicket': {
    params: CreateTicketParams
    result: void
  }
  'account.deleteRecentVisit': {
    params: DeleteRecentVisitParams
    result: void
  }
  'account.forgotPassword': {
    params: ForgotPasswordParams
    result: void
  }
  'account.getAuthenticationType': {
    params: GetAuthenticationTypeParams
    result: GetAuthenticationTypeResponse
  }
  'account.getUserProfile': {
    params: never
    result: UserProfile
  }
  'account.listRecentVisits': {
    params: never
    result: UserRecentVisitList
  }
  'account.login': {
    params: LoginParams
    result: string
  }
  'account.loginSso': {
    params: LoginSsoParams
    result: string
  }
  'account.loginZendesk': {
    params: never
    result: ZendeskLogin
  }
  'account.logout': {
    params: never
    result: void
  }
  'account.resetPassword': {
    params: ResetPasswordParams
    result: void
  }
  'account.trackVisit': {
    params: TrackVisitParams
    result: void
  }
  'account.unsubscribeEmail': {
    params: UnsubscribeEmailParams
    result: void
  }
  'account.updatePassword': {
    params: UpdatePasswordParams
    result: void
  }
  'account.updateUserProfile': {
    params: UpdateUserProfileParams
    result: void
  }
  'admin.createUser': {
    params: CreateUserParams
    result: UserAccount
  }
  'admin.deactivateUser': {
    params: DeactivateUserParams
    result: void
  }
  'admin.deleteUser': {
    params: DeleteUserParams
    result: void
  }
  'admin.exportUserSessions': {
    params: ExportUserSessionsParams
    result: string
  }
  'admin.exportUsers': {
    params: ExportUsersParams
    result: string
  }
  'admin.getUser': {
    params: GetUserParams
    result: UserAccount
  }
  'admin.getUserInvitationLink': {
    params: GetUserInvitationLinkParams
    result: string
  }
  'admin.listUserSessions': {
    params: ListUserSessionsParams
    result: UserSessionList
  }
  'admin.listUsers': {
    params: ListUsersParams
    result: UserList
  }
  'admin.reactivateUser': {
    params: ReactivateUserParams
    result: void
  }
  'admin.resendInvitation': {
    params: ResendInvitationParams
    result: void
  }
  'admin.updateUser': {
    params: UpdateUserParams
    result: void
  }
  'data.addToDealCloud': {
    params: AddToDealCloudParams
    result: DealCloudItem[]
  }
  'data.downloadAllShareholderFiles': {
    params: DownloadAllShareholderFilesParams
    result: string
  }
  'data.downloadAssetAnnualReportFiles': {
    params: DownloadAssetAnnualReportFilesParams
    result: string
  }
  'data.downloadAssetFiles': {
    params: DownloadAssetFilesParams
    result: string
  }
  'data.downloadLegalEntityAnnualReportFiles': {
    params: DownloadLegalEntityAnnualReportFilesParams
    result: string
  }
  'data.exportAdvisorDeals': {
    params: ExportAdvisorDealsParams
    result: string
  }
  'data.exportAdvisors': {
    params: ExportAdvisorsParams
    result: string
  }
  'data.exportAnnualReportFile': {
    params: ExportAnnualReportFileParams
    result: AnnualReportDownloadURLs
  }
  'data.exportAssetDeals': {
    params: ExportAssetDealsParams
    result: string
  }
  'data.exportAssetFinancials': {
    params: ExportAssetFinancialsParams
    result: string
  }
  'data.exportAssets': {
    params: ExportAssetsParams
    result: string
  }
  'data.exportConferenceEditionExhibitors': {
    params: ExportConferenceEditionExhibitorsParams
    result: string
  }
  'data.exportDeals': {
    params: ExportDealsParams
    result: string
  }
  'data.exportFactsheet': {
    params: ExportFactsheetParams
    result: string
  }
  'data.exportIndustryProfile': {
    params: ExportIndustryProfileParams
    result: string
  }
  'data.exportInvestors': {
    params: ExportInvestorsParams
    result: string
  }
  'data.exportLegalEntityFinancials': {
    params: ExportLegalEntityFinancialsParams
    result: string
  }
  'data.exportShareholderFile': {
    params: ExportShareholderFileParams
    result: string
  }
  'data.getActiveConsolidators': {
    params: GetActiveConsolidatorsParams
    result: ActiveConsolidator[]
  }
  'data.getActiveInvestors': {
    params: GetActiveInvestorsParams
    result: ActiveInvestor[]
  }
  'data.getActiveStrategicAcquirers': {
    params: GetActiveStrategicAcquirersParams
    result: ActiveStrategicAcquirer[]
  }
  'data.getAdvisor': {
    params: GetAdvisorParams
    result: Advisor
  }
  'data.getAdvisorAssetClients': {
    params: GetAdvisorAssetClientsParams
    result: AdvisorAssetClient[]
  }
  'data.getAdvisorInvestorClients': {
    params: GetAdvisorInvestorClientsParams
    result: AdvisorInvestorClient[]
  }
  'data.getArticle': {
    params: GetArticleParams
    result: Article
  }
  'data.getAsset': {
    params: GetAssetParams
    result: Asset
  }
  'data.getAssetAdvisors': {
    params: GetAssetAdvisorsParams
    result: AssetAdvisor[]
  }
  'data.getAssetConferenceEditions': {
    params: GetAssetConferenceEditionsParams
    result: ConferenceEditionListItem[]
  }
  'data.getAssetLinkedinData': {
    params: GetAssetLinkedinDataParams
    result: Linkedin
  }
  'data.getAssetOwnershipDistribution': {
    params: GetAssetOwnershipDistributionParams
    result: AssetOwnershipCount[]
  }
  'data.getAssetRelevantIndustries': {
    params: GetAssetRelevantIndustriesParams
    result: IndustryList
  }
  'data.getCityByPlaceId': {
    params: GetCityByPlaceIdParams
    result: GetCityByPlaceIdResponse
  }
  'data.getCompetitor': {
    params: GetCompetitorParams
    result: Competitor
  }
  'data.getDeal': {
    params: GetDealParams
    result: Deal
  }
  'data.getEmailAddress': {
    params: GetEmailAddressParams
    result: GetEmailAddressResult
  }
  'data.getGeometryForPlaceId': {
    params: GetGeometryForPlaceIdParams
    result: GeoPolygon
  }
  'data.getGlobalUltimateOwner': {
    params: GetGlobalUltimateOwnerParams
    result: GlobalUltimateOwner
  }
  'data.getIndustry': {
    params: GetIndustryParams
    result: Industry
  }
  'data.getIndustryMarketSegmentById': {
    params: GetIndustryMarketSegmentByIdParams
    result: IndustryMarketSegmentListItem
  }
  'data.getIndustrySegmentDealsByYear': {
    params: GetIndustrySegmentDealsByYearParams
    result: IndustrySegmentDealByYear[]
  }
  'data.getInvestor': {
    params: GetInvestorParams
    result: Investor
  }
  'data.getInvestorDealsPerTypeByYear': {
    params: GetInvestorDealsPerTypeByYearParams
    result: InvestorDealsPerTypeByYear[]
  }
  'data.getInvestorProfileStrategies': {
    params: GetInvestorProfileStrategiesParams
    result: InvestorProfileStrategy[]
  }
  'data.getInvestorStrategy': {
    params: GetInvestorStrategyParams
    result: InvestorStrategy
  }
  'data.getLatestFeature': {
    params: never
    result: BeamerPost
  }
  'data.getLegalEntity': {
    params: GetLegalEntityParams
    result: LegalEntity
  }
  'data.getListedSecurityChartData': {
    params: GetListedSecurityChartDataParams
    result: ListedSecurityChartData
  }
  'data.getListedSecurityValuation': {
    params: GetListedSecurityValuationParams
    result: ListedSecurityValuationMetrics
  }
  'data.getListedSecurityValuations': {
    params: GetListedSecurityValuationsParams
    result: ListedSecurityValuation[]
  }
  'data.getLngLatByPlaceId': {
    params: GetLngLatByPlaceIdParams
    result: GetLonLatByPlaceIdResponse
  }
  'data.getPerson': {
    params: GetPersonParams
    result: Person
  }
  'data.getRelatedAssets': {
    params: GetRelatedAssetsParams
    result: AssetList
  }
  'data.getRelatedConferenceEditions': {
    params: GetRelatedConferenceEditionsParams
    result: RelatedConferenceEdition[]
  }
  'data.getRelatedDealAdvisors': {
    params: GetRelatedDealAdvisorsParams
    result: RelatedDealAdvisor[]
  }
  'data.getSector': {
    params: GetSectorParams
    result: Sector
  }
  'data.getSimilarAssets': {
    params: GetSimilarAssetsParams
    result: AssetList
  }
  'data.getSubsector': {
    params: GetSubsectorParams
    result: Subsector
  }
  'data.listAdvisorDeals': {
    params: ListAdvisorDealsParams
    result: AdvisorDealsList
  }
  'data.listAdvisors': {
    params: ListAdvisorsParams
    result: AdvisorList
  }
  'data.listArticles': {
    params: ListArticlesParams
    result: ArticleList
  }
  'data.listAssets': {
    params: ListAssetsParams
    result: AssetList
  }
  'data.listAssociatedTags': {
    params: ListAssociatedTagsParams
    result: AssociatedTag[]
  }
  'data.listCompetitors': {
    params: ListCompetitorsParams
    result: CompetitorList
  }
  'data.listConferenceEditions': {
    params: ListConferenceEditionsParams
    result: ConferenceEditionList
  }
  'data.listCurrencies': {
    params: ListCurrenciesParams
    result: CurrencyList
  }
  'data.listDeals': {
    params: ListDealsParams
    result: DealList
  }
  'data.listIndustries': {
    params: ListIndustriesParams
    result: IndustryList
  }
  'data.listIndustryMarketSegments': {
    params: ListIndustryMarketSegmentsParams
    result: IndustryMarketSegmentList
  }
  'data.listInvestorFunds': {
    params: ListInvestorFundsParams
    result: InvestorFundList
  }
  'data.listInvestorStrategies': {
    params: ListInvestorStrategiesParams
    result: InvestorStrategyList
  }
  'data.listInvestors': {
    params: ListInvestorsParams
    result: InvestorList
  }
  'data.listLegalEntities': {
    params: ListLegalEntitiesParams
    result: LegalEntityList
  }
  'data.listListedSecurities': {
    params: ListListedSecuritiesParams
    result: ListedSecurityList
  }
  'data.listPersons': {
    params: ListPersonsParams
    result: PersonList
  }
  'data.listRegions': {
    params: ListRegionsParams
    result: RegionList
  }
  'data.listSectors': {
    params: ListSectorsParams
    result: SectorList
  }
  'data.listSimilarAssetDeals': {
    params: ListSimilarAssetDealsParams
    result: SimilarAssetDealsList
  }
  'data.listSubsectors': {
    params: ListSubsectorsParams
    result: SubsectorList
  }
  'data.listTags': {
    params: ListTagsParams
    result: TagList
  }
  'data.search': {
    params: SearchParams
    result: SearchResultItem[]
  }
  'data.searchDomain': {
    params: SearchDomainParams
    result: SearchResultItem[]
  }
  'data.suggestCity': {
    params: SuggestCityParams
    result: SuggestCityResponse[]
  }
  'data.suggestLocality': {
    params: SuggestLocalityParams
    result: GeoPolygon[]
  }
  'data.suggestTags': {
    params: SuggestTagsParams
    result: SuggestedTag[]
  }
  'data.trackActivity': {
    params: TrackActivityParams
    result: void
  }
  'lists.createCustomAssetList': {
    params: CreateCustomAssetListParams
    result: CustomAssetList
  }
  'lists.createCustomAssetQuery': {
    params: CreateCustomAssetQueryParams
    result: CustomAssetQueryListItem
  }
  'lists.customAssetListAddAssets': {
    params: CustomAssetListAddAssetsParams
    result: CustomAssetList
  }
  'lists.customAssetListImportFromUrls': {
    params: CustomAssetListImportFromUrlsParams
    result: number
  }
  'lists.customAssetListRemoveAssets': {
    params: CustomAssetListRemoveAssetsParams
    result: CustomAssetList
  }
  'lists.deleteCustomAssetList': {
    params: DeleteCustomAssetListParams
    result: void
  }
  'lists.deleteCustomAssetQuery': {
    params: DeleteCustomAssetQueryParams
    result: void
  }
  'lists.getCustomAssetList': {
    params: GetCustomAssetListParams
    result: CustomAssetList
  }
  'lists.getCustomAssetListUrlImportTaskState': {
    params: GetCustomAssetListUrlImportTaskStateParams
    result: GetGetCustomAssetListUrlImportTaskStateResult
  }
  'lists.getCustomAssetLists': {
    params: never
    result: CustomAssetList[]
  }
  'lists.getCustomAssetQuery': {
    params: GetCustomAssetQueryParams
    result: CustomAssetQueryListItem
  }
  'lists.getDealsPerTypeByYear': {
    params: GetDealsPerTypeByYearParams
    result: DealsPerTypeByYear[]
  }
  'lists.getListRatings': {
    params: GetListRatingsParams
    result: CustomAssetRatings
  }
  'lists.leaveCustomAssetList': {
    params: LeaveCustomAssetListParams
    result: void
  }
  'lists.leaveCustomAssetQuery': {
    params: LeaveCustomAssetQueryParams
    result: void
  }
  'lists.listCustomAssetQueries': {
    params: ListCustomAssetQueriesParams
    result: CustomAssetQueryList
  }
  'lists.listUpdates': {
    params: ListUpdatesParams
    result: AssetList
  }
  'lists.setCustomAssetListLastViewedAt': {
    params: SetCustomAssetListLastViewedAtParams
    result: CustomAssetList
  }
  'lists.setCustomAssetQueryLastViewedAt': {
    params: SetCustomAssetQueryLastViewedAtParams
    result: void
  }
  'lists.shareCustomAssetList': {
    params: ShareCustomAssetListParams
    result: void
  }
  'lists.shareCustomAssetQuery': {
    params: ShareCustomAssetQueryParams
    result: void
  }
  'lists.transferCustomAssetList': {
    params: TransferCustomAssetListParams
    result: void
  }
  'lists.transferCustomAssetQuery': {
    params: TransferCustomAssetQueryParams
    result: void
  }
  'lists.unshareCustomAssetList': {
    params: UnshareCustomAssetListParams
    result: void
  }
  'lists.unshareCustomAssetQuery': {
    params: UnshareCustomAssetQueryParams
    result: void
  }
  'lists.updateCustomAssetList': {
    params: UpdateCustomAssetListParams
    result: CustomAssetList
  }
  'lists.updateCustomAssetQuery': {
    params: UpdateCustomAssetQueryParams
    result: CustomAssetQueryListItem
  }
  'rpc.enums': {
    params: never
    result: EnumInfo[]
  }
  'rpc.error': {
    params: ErrorParams
    result: void
  }
  'rpc.errorCodes': {
    params: never
    result: ErrorInfo[]
  }
  'rpc.ping': {
    params: never
    result: string
  }
  'rpc.services': {
    params: never
    result: RPCServiceInfo[]
  }
  'rpc.types': {
    params: never
    result: TypeInfo[]
  }
}
