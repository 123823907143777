import { useResizeObserver } from '@gain/utils/dom'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import Tab from '@mui/material/Tab'
import Tabs, { TabsProps } from '@mui/material/Tabs'
import { isEqual } from 'lodash'
import { useCallback, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import { TAB_MARGIN_HORIZONTAL } from '../../theme/components/mui-tab'
import { useOpenLink } from '../use-open-link'
import { ProfileTab } from './profile-info-and-tabs-model'

const StyledTabs = styled(Tabs)<TabsProps>({
  width: '100%',
})

const StyledHiddenMeasuringDiv = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  visibility: 'hidden',
})

interface ProfileTabsProps
  extends Omit<TabsProps, 'variant' | 'children' | 'value' | 'TabIndicatorProps'> {
  tabs?: ProfileTab[]
  activeTab?: string
  replaceUrl?: boolean
  disableIndicatorAnimation?: boolean
}

export default function ProfileTabsOverflow({
  tabs,
  activeTab,
  replaceUrl,
  disableIndicatorAnimation,
  ...tabsProps
}: ProfileTabsProps) {
  const openLink = useOpenLink()
  const tabContainerRef = useRef<HTMLDivElement>(null)
  const tabRefs = useRef<HTMLDivElement[]>([])
  const buttonMoreRef = useRef<HTMLDivElement>(null)
  const [tabsHidden, setTabsHidden] = useState<string[]>([])
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState<null | HTMLElement>(null)

  const calculateTabsToShow = useCallback(() => {
    if (!tabContainerRef.current || !buttonMoreRef.current || !tabs) {
      return
    }

    // 1. First pass to calculate the total width if all tabs are shown
    let currentWidth = 0
    tabs.forEach((tab, index) => {
      if (index !== 0) {
        currentWidth += 2 * TAB_MARGIN_HORIZONTAL // Add margin between tabs
      }
      currentWidth += tabRefs.current[index].clientWidth // Add width of the current tab
    })

    // 2. If the total width is less than the container width, stop here and show all tabs
    const totalWidth = tabContainerRef.current.clientWidth
    if (currentWidth <= totalWidth) {
      setTabsHidden((prevState) => (isEqual(prevState, []) ? prevState : [])) // Avoids unnecessary re-renders
      return
    }

    // 3. Otherwise, we will show as many tabs as fit in the container

    // Start with the full list of tabs and add the "more" tab
    const newTabsHidden: string[] = []
    currentWidth += 2 * TAB_MARGIN_HORIZONTAL + buttonMoreRef.current.clientWidth

    // Remove tabs from the list until the total width is less than the container width
    let index = tabs.length - 1
    while (currentWidth > totalWidth && index >= 0) {
      // Only hide tabs that are not the active tab
      if (tabs[index].value !== activeTab) {
        currentWidth -= 2 * TAB_MARGIN_HORIZONTAL + tabRefs.current[index].clientWidth
        newTabsHidden.push(tabs[index].value)
      }
      index--
    }

    // Only update the state if the new list of hidden tabs is different from the previous list,
    // to avoid unnecessary re-renders.
    setTabsHidden((prevState) => (isEqual(prevState, newTabsHidden) ? prevState : newTabsHidden))
  }, [tabs, activeTab])

  useResizeObserver(tabContainerRef, calculateTabsToShow)

  if (!tabs || tabs.length === 0 || !activeTab || !tabs.find((tab) => tab.value === activeTab)) {
    return null
  }

  const handleClickMore = (event: React.MouseEvent<HTMLElement>) => {
    setMoreMenuAnchorEl(event.currentTarget)
  }

  const handleClickTabInMenu = (event: React.MouseEvent<HTMLElement>, tab: ProfileTab) => {
    openLink(tab.path, event)
    setMoreMenuAnchorEl(null)
  }

  const handleCloseMenu = () => {
    setMoreMenuAnchorEl(null)
  }

  return (
    <>
      <StyledHiddenMeasuringDiv>
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            ref={(el) => {
              if (el) {
                tabRefs.current[index] = el
              }
            }}
            label={tab.label}
          />
        ))}
        <Tab
          ref={buttonMoreRef}
          label={'X more'} // One digit is enough for the "more" tab
        />
      </StyledHiddenMeasuringDiv>
      <StyledTabs
        ref={tabContainerRef}
        TabIndicatorProps={{ style: { ...(disableIndicatorAnimation && { transition: 'none' }) } }}
        value={tabsHidden.includes(activeTab) ? false : activeTab}
        variant={'standard'}
        {...tabsProps}>
        {tabs
          .filter((tab) => !tabsHidden.includes(tab.value)) // Only show tabs that are not hidden
          .map((tab, index) => (
            <Tab
              key={index}
              aria-controls={`nav-tabpanel-${index}`}
              component={Link}
              id={`nav-tab-${index}`}
              label={tab.label}
              replace={replaceUrl}
              to={tab.path}
              value={tab.value}
            />
          ))}
        {tabsHidden.length > 0 && (
          // This is the "X more" tab that appears when there is not enough space for all tabs
          <Tab
            label={`${tabsHidden.length} more`}
            onClick={handleClickMore}
            style={{ marginLeft: 2 * TAB_MARGIN_HORIZONTAL }}
          />
        )}
      </StyledTabs>
      {tabsHidden.length > 0 && (
        // This is the menu that appears when clicking the "X more" tab
        <Menu
          anchorEl={moreMenuAnchorEl}
          onClose={handleCloseMenu}
          open={!!moreMenuAnchorEl}>
          {tabsHidden.map((tabValue) => {
            const tab = tabs.find((t) => t.value === tabValue)

            // The tab should always be found because tabsHidden is a subset of tabs.
            // However, it can happen that the tabs have been dynamically updated and
            // the useEffect hook that calculates the hidden tabs has not run yet.
            // In that case, we just skip rendering the tab for this render cycle.
            if (!tab) {
              return null
            }

            return (
              <MenuItem
                key={tabValue}
                onClick={(event) => handleClickTabInMenu(event, tab)}>
                {tab.label}
              </MenuItem>
            )
          })}
        </Menu>
      )}
    </>
  )
}
