import Typography from '@gain/components/typography'
import { isNotDefined } from '@gain/utils/common'
import { FormatCurrencyOptions, useFormatCurrencyCallback } from '@gain/utils/currency'
import { formatYearAndQuarter } from '@gain/utils/date'
import Chip from '@mui/material/Chip'
import { styled, Theme } from '@mui/material/styles'
import { SxProps } from '@mui/system'
import React from 'react'

const StyledRoot = styled('div')({
  display: 'inline-flex',
  alignItems: 'center',
  gap: 6,
})

interface FinancialValueProps {
  sx?: SxProps<Theme>
  amount?: number | null
  year?: number | null
  quarter?: number | null
  round?: number | 'auto'
  dataCurrency?: string | null
  toCurrency?: string | null
  format?: FormatCurrencyOptions['format']

  // If true colors the value blue
  isEstimate?: boolean
  // If true the ai chip is show
  isAiGenerated?: boolean
}

export default function FinancialValue({
  amount,
  year,
  quarter,
  round,
  dataCurrency,
  toCurrency,
  isEstimate = false,
  isAiGenerated = false,
  format,
  sx,
}: FinancialValueProps) {
  const formatCurrency = useFormatCurrencyCallback(dataCurrency, toCurrency)
  const chipLabel = formatYearAndQuarter(year, quarter)

  if (isNotDefined(amount)) {
    return <>-</>
  }

  // Determine text color
  let textColor = 'text.primary'
  if (isAiGenerated) {
    textColor = 'text.secondary'
  } else if (isEstimate) {
    textColor = 'info.main'
  }

  return (
    <StyledRoot sx={sx}>
      <Typography
        color={textColor}
        fontWeight={'inherit'}
        variant={'body2'}
        noWrap>
        {formatCurrency(amount, { round: isAiGenerated ? 0 : round, format })}
      </Typography>
      {isAiGenerated && amount !== null ? (
        <Chip
          color={'warning'}
          label={'AI'}
          size={'small'}
        />
      ) : chipLabel && amount !== null ? (
        <Chip
          label={chipLabel}
          size={'small'}
        />
      ) : null}
    </StyledRoot>
  )
}
