import { useAssetList } from 'libs/api/app/hooks/src/app-api-hooks'
import { listFilter, listFilters, listSort } from 'libs/rpc/utils/src/rpc-list-util'

export function useAssetSubsidiaries(subsidiaryPath: number[] | null) {
  return useAssetList(
    subsidiaryPath
      ? {
          filter: listFilters(listFilter('subsidiaryPath', '<@', subsidiaryPath)),
          sort: [listSort('name', 'asc')],
          limit: 500,
        }
      : null
  )
}
