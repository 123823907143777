import { CurrencyListItem } from '@gain/rpc/app-model'
import {
  FinancialResult,
  formatRevenueFte,
  useFinancialsWithMissingYears,
} from '@gain/utils/financials'
import { formatMultiple, formatPercentage } from '@gain/utils/number'
import React from 'react'

import Card, { CardHeader } from '../../../common/card/card'
import CurrencyToggle from '../../currency-toggle'
import FinancialResultsTable from '../financial-results-table'
import {
  useLastReportedFinancialYearIndex,
  useVisibleFinancials,
} from '../financial-results-table/financial-results-table-hooks'
import {
  formatAmount,
  RowGroupConfig,
} from '../financial-results-table/financial-results-table-utils'

const RATIO_ROW_GROUPS = new Array<RowGroupConfig>(
  [
    {
      label: 'Return ratios',
    },
    {
      label: 'Return on assets',
      valueFn: (financial) => financial.returnOnAssets,
      formatFn: (value) => formatPercentage(value, { round: 1 }),
    },
    {
      label: 'Return on equity',
      valueFn: (financial) => financial.returnOnEquity,
      formatFn: (value) => formatPercentage(value, { round: 2 }),
    },
    {
      label: 'Return on capital employed',
      valueFn: (financial) => financial.returnOnCapitalEmployed,
      formatFn: (value) => formatPercentage(value, { round: 2 }),
      labelLetterSpacing: -0.3,
    },
  ],

  [
    {
      label: 'Other ratios',
    },
    {
      label: 'Revenue / FTE',
      valueFn: (financial) => financial.revenueFteRatio,
      formatFn: formatRevenueFte,
      bold: false,
    },
    {
      label: 'Net debt / EBITDA',
      valueFn: (financial) => financial.netDebtEbitdaRatio,
      formatFn: (value) => formatMultiple(value, { round: 1 }),
    },
    {
      label: 'EBITDA - CAPEX',
      valueFn: (financial) => financial.ebitdaMinusCapex,
      formatFn: formatAmount,
    },
  ]
)

interface RatiosCardProps {
  financialResults: FinancialResult[]
  dataCurrency?: string | null
  currency: CurrencyListItem | null
  onCurrencyChange: (newCurrency: CurrencyListItem) => void
}

export default function RatiosCard({
  financialResults,
  dataCurrency,
  currency,
  onCurrencyChange,
}: RatiosCardProps) {
  const financialsWithMissingYears = useFinancialsWithMissingYears(financialResults)
  const visibleFinancials = useVisibleFinancials(financialsWithMissingYears)
  const lastReportedFinancialYearIndex = useLastReportedFinancialYearIndex(visibleFinancials)

  return (
    <Card sx={{ pb: 0 }}>
      <CardHeader
        actions={
          <CurrencyToggle
            dataCurrency={dataCurrency}
            onChange={onCurrencyChange}
            value={currency}
          />
        }
        title={'Ratios'}
      />
      <FinancialResultsTable
        currency={currency}
        financialResults={financialsWithMissingYears}
        rightColumnIndex={lastReportedFinancialYearIndex}
        rowGroups={RATIO_ROW_GROUPS}
      />
    </Card>
  )
}
