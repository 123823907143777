import { useSimilarAssets } from '@gain/api/app/hooks'
import { CurrencyListItem } from '@gain/rpc/app-model'
import { listFilter, listFilters } from '@gain/rpc/utils'
import { useVisibleColumns } from '@gain/utils/table'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import React, { useRef } from 'react'

import Card, { CardHeader } from '../../../../common/card/card'
import Table from '../../../../common/table'
import CurrencyToggle from '../../../../features/currency-toggle'
import {
  columnVisibility,
  summaryRows,
  useCreateTableColumns,
  useCreateTableSummaryColumns,
} from './asset-similar-performance-card-columns'
import TableSummary from './table-summary'

// The number of assets to show in the similar performance card.
const MAX_PERFORMANCE_SIMILAR_ASSETS = 8

interface SimilarPerformanceCardProps {
  similarToAssetId: number
  dataCurrency?: string | null
  currency: CurrencyListItem | null

  // The currency toggle will be shown if the currency and dataCurrency are different
  onCurrencyChange: (newCurrency: CurrencyListItem) => void
}

/**
 * Card that shows similar company performance. This card is shown on the asset
 * financials page when there are AI financials and are intended to add context
 * how we came to the AI financials.
 *
 * AI financials are calculated based on the performance of similar companies
 * and can be off; which is fine as long as the user understands the context
 * and assess themselves if the AI financials are in the right ballpark.
 */
export default function SimilarPerformanceCard({
  similarToAssetId,
  dataCurrency,
  currency,
  onCurrencyChange,
}: SimilarPerformanceCardProps) {
  const tableRef = useRef<HTMLDivElement>(null)
  const columns = useCreateTableColumns(currency?.name)
  const summaryColumns = useCreateTableSummaryColumns(currency?.name)
  const visibleColumns = useVisibleColumns(tableRef, columns, columnVisibility)

  // Only show similar assets with any "Revenue / FTE" value
  const swrSimilarAssets = useSimilarAssets(
    similarToAssetId,
    MAX_PERFORMANCE_SIMILAR_ASSETS,
    listFilters(listFilter('revenueFteRatioEur', '>', -999999)) // There is no "is not null" filter
  )

  return (
    <Card sx={{ pb: 0 }}>
      <CardHeader
        actions={
          <CurrencyToggle
            dataCurrency={dataCurrency}
            onChange={onCurrencyChange}
            value={currency}
          />
        }
        title={
          <Stack
            alignItems={'center'}
            direction={'row'}
            flexWrap={'wrap'}
            gap={1}>
            <Typography
              color={'text.primary'}
              variant={'h5'}
              noWrap>
              Similar company performance
            </Typography>
          </Stack>
        }
      />

      <Table
        ref={tableRef}
        amountOfPlaceholders={8}
        columns={visibleColumns}
        rows={swrSimilarAssets.data.items}
      />

      <TableSummary
        columns={visibleColumns}
        rows={swrSimilarAssets.data.items}
        summaryColumns={summaryColumns}
        summaryRows={summaryRows}
      />
    </Card>
  )
}
