/**
 * The available aggregation methods for the table summary. The object key is
 * the identifier of the method and can be referenced in the `summaryColumns`.
 */
export const aggregationMethods = {
  min: {
    label: 'Min.',
    calculate: (numbers: number[]) => (numbers.length > 0 ? Math.min(...numbers) : null),
  },
  max: {
    label: 'Max.',
    calculate: (numbers: number[]) => (numbers.length > 0 ? Math.max(...numbers) : null),
  },
  median: {
    label: 'Median',
    calculate: (numbers: number[]) => {
      if (numbers.length === 0) {
        return null
      }

      const sorted = [...numbers].sort((a, b) => a - b)
      const middle = Math.floor(sorted.length / 2)

      if (sorted.length % 2 === 0) {
        return (sorted[middle - 1] + sorted[middle]) / 2
      }

      return sorted[middle]
    },
  },
}

/**
 * Get all numeric values of a column from a list of items.
 */
export function columnValues<T>(assets: T[], key: keyof T): number[] {
  return assets.reduce<number[]>((acc, asset) => {
    const value = asset[key]
    if (value !== undefined && value !== null && typeof value === 'number') {
      acc.push(value)
    }
    return acc
  }, [])
}
