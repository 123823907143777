import Flag from '@gain/components/flag'
import { AssetListItem } from '@gain/rpc/app-model'
import { useSplitList } from '@gain/utils/list'
import Divider from '@mui/material/Divider'
import { formatAssetCustomerBaseLabel } from 'libs/utils/asset/src/format-asset-customer-base-label'
import { formatSector } from 'libs/utils/sector/src/sector-formatters'
import { useState } from 'react'

import Card, { CardHeader, CardProps } from '../../../../common/card/card'
import ViewMoreButton from '../../../../common/view-more-button'
import { createVirtualTableColumns } from '../../../../common/virtual-table/create-virtual-table-columns'
import VirtualTable from '../../../../common/virtual-table/virtual-table'
import AssetContentLink from '../../../../features/asset/asset-content-link/asset-content-link'
import AssetTags from '../../../../features/asset/asset-tags/asset-tags'

const columns = createVirtualTableColumns<AssetListItem>(
  {
    headerName: 'Name',
    field: 'name',
    width: 208,
    sticky: true,
    sortable: false,
    renderCell: ({ row }) => (
      <AssetContentLink
        asset={row}
        id={row.id}
        logoFileUrl={row.logoFileUrl}
        name={row.name}
      />
    ),
  },
  {
    field: 'description',
    headerName: 'Business description',
    width: 200,
    flex: 1,
    sortable: false,
    valueFormatter: ({ value }) => value || '-',
  },
  {
    field: 'sector',
    headerName: 'Sector',
    width: 160,
    sortable: false,
    valueFormatter: ({ value }) => formatSector(value),
  },
  {
    field: 'tagIds',
    headerName: 'Tags',
    align: 'left',
    width: 344,
    sortable: false,
    renderCell: ({ row }) => (
      <AssetTags
        tagIds={row.tagIds}
        tags={row.tags}
      />
    ),
  },
  {
    field: 'customerBase',
    headerName: 'Customer base',
    align: 'left',
    width: 160,
    sortable: false,
    valueFormatter: ({ value }) =>
      value.map((type) => formatAssetCustomerBaseLabel(type)).join(', ') || '-',
  },
  {
    field: 'region',
    headerName: 'HQ',
    align: 'right',
    width: 56,
    sortable: false,
    renderCell: ({ value }) => <Flag code={value} />,
  }
)

const INITIAL_SIZE = 6

export interface CardAssetSubsidiariesProps extends CardProps {
  subsidiaries: AssetListItem[]
}

export default function CardAssetSubsidiaries({ subsidiaries }: CardAssetSubsidiariesProps) {
  const [limitedRows, otherRows] = useSplitList(subsidiaries, INITIAL_SIZE, false)
  const [showAll, setShowAll] = useState(false)

  return (
    <Card fullHeight>
      <CardHeader title={'Subsidiaries'} />
      <VirtualTable
        columns={columns}
        RowComponentProps={() => ({
          hover: false,
        })}
        rows={showAll ? subsidiaries : limitedRows}
        variant={'inline'}
        disablePaddingStart
      />
      {!showAll && otherRows.length > 0 && (
        <>
          <Divider />
          <ViewMoreButton
            amount={otherRows.length}
            onClick={() => setShowAll(true)}
            variant={'chevron-down'}
          />
        </>
      )}
    </Card>
  )
}
