import type { RowType } from '@gain/utils/table'
import { styled, Theme } from '@mui/material/styles'
import { SxProps } from '@mui/system'
import type { ReactNode } from 'react'

import ConditionalWrapper from '../../../../common/conditional-wrapper'
import type { ColumnConfig } from '../../../../common/table'
import { aggregationMethods, columnValues } from './table-summary-utils'

const Container = styled('div')(({ theme }) => ({
  paddingBBlock: theme.spacing(0.5),
  borderTop: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.grey['100'],

  // The bottom is rounded to match the Card component
  borderBottomLeftRadius: theme.shape.borderRadius,
  borderBottomRightRadius: theme.shape.borderRadius,
}))

const StyledTable = styled('table')(({ theme }) => ({
  width: '100%',
  maxWidth: '100%',
  minWidth: 0,
  borderCollapse: 'separate',
  borderSpacing: 0,
  boxSizing: 'border-box',
  tableLayout: 'fixed',
  paddingBlock: theme.spacing(0.5),

  '& td': {
    paddingInline: theme.spacing(1),
    paddingBlock: theme.spacing(0.5),

    '&:first-of-type': {
      paddingLeft: theme.spacing(3),
    },

    '&:last-of-type': {
      paddingRight: theme.spacing(3),
    },
  },
}))

const StyledTd = styled('td')(({ theme }) => ({
  ...theme.typography.body2,
}))

/**
 * Each summary row maps to an aggregation method ("min", "max", etc...).
 */
export interface SummaryRowConfig {
  method: keyof typeof aggregationMethods
  bold?: boolean
  sx?: SxProps<Theme>
}

type RenderSummaryCell = (value: number | null) => ReactNode

/**
 * Defines which columns to summarize and how to render the cell. The aggregation
 * method is defined in the `summaryRows`. The renderCell receives the aggregated
 * value of all rows in the column.
 */
export interface SummaryColumnConfig<Row extends RowType> {
  field: Extract<keyof Row, string>
  renderCell: RenderSummaryCell
}

interface TableSummaryProps<Row extends RowType> {
  columns: Array<ColumnConfig<Row>>
  rows: Array<Row>
  summaryColumns: SummaryColumnConfig<Row>[]
  summaryRows: SummaryRowConfig[]
}

/**
 * Table summary component that shows a summary of the table data.
 *
 * Note: this is a first version built under time constraints and should be
 * improved in the future when we use it more.
 */
export default function TableSummary<Row extends RowType>({
  columns,
  rows,
  summaryColumns,
  summaryRows,
}: TableSummaryProps<Row>) {
  // On initial render, responsive calculations may result in empty columns.
  // Additionally, rows might not be retrieved yet. In such cases, return null
  // to prevent rendering an incomplete or empty table.
  if (columns.length === 0 || rows.length === 0) {
    return null
  }

  // Due to responsive design, some columns might not be visible. We only want
  // to show the summary columns that are visible in the main table.
  const visibleSummaryColumns = summaryColumns.filter((column) =>
    columns.some((col) => col.field === column.field)
  )

  /**
   * Calculate the colspan for a cell in the summary table. This allows us to
   * use more space when columns are very narrow.
   */
  const calculateColspan = (currentColumnField?: string) => {
    // Find current column index
    const currentColIndex = columns.findIndex((column) => column.field === currentColumnField)
    if (currentColIndex === -1 || currentColIndex === columns.length - 1) {
      return 1 // Colspan of 1 for the last column or if the column is not found
    }

    // Find current aggregation index
    const currentAggregationIndex = visibleSummaryColumns.findIndex(
      (column) => column.field === currentColumnField
    )
    if (currentAggregationIndex === visibleSummaryColumns.length - 1) {
      return columns.length - currentColIndex // Span all remaining columns
    }

    // Find next aggregation col index
    const nextAggregation = visibleSummaryColumns[currentAggregationIndex + 1]
    const nextColIndex = columns.findIndex((column) => column.field === nextAggregation.field)

    // Return the difference between the next and current column index
    return nextColIndex - currentColIndex
  }

  return (
    <Container>
      <StyledTable>
        {/* We use a colgroup to align the columns with the main table */}
        <colgroup>
          {columns.map((column) => (
            <col
              key={column.field}
              width={column.width}
            />
          ))}
        </colgroup>

        {/* Each aggregation method is a row */}
        {summaryRows.map(({ method, bold }, aggregationIndex) => (
          <tr key={`row-${aggregationIndex}`}>
            <StyledTd
              key={method}
              colSpan={calculateColspan(columns[0].field)}>
              <ConditionalWrapper
                condition={bold}
                wrapper={(children) => <strong>{children}</strong>}>
                {aggregationMethods[method].label}
              </ConditionalWrapper>
            </StyledTd>

            {visibleSummaryColumns.map((column) => (
              <StyledTd
                key={column.field}
                colSpan={calculateColspan(column.field)}
                sx={{ textAlign: 'right' }}>
                <ConditionalWrapper
                  condition={bold}
                  wrapper={(children) => <strong>{children}</strong>}>
                  {column.renderCell(
                    aggregationMethods[method].calculate(columnValues(rows, column.field))
                  )}
                </ConditionalWrapper>
              </StyledTd>
            ))}
          </tr>
        ))}
      </StyledTable>
    </Container>
  )
}
